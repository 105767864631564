<template>
  <div class="footer">
        <div id="footer-menu" class="row no-gutters justify-content-center">
            <div v-for="(item, index) in itensMenuInferior" :key="index">
                <div class="col-auto" v-if="item.link!='/transacoes'||$store.getters.GetUltimasTransacoes.length>0">
                    <a  @click="$router.push(item.link)" :class="{ active : itemdestacar == (index+1) }" class=" ponteiro">
                        <i  class="material-icons">{{item.icone}}</i>
                        <p>{{item.descricao}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemdestacar: {
            type: Number,
            required: false,
        }
    },
    data() {
        return {
            active_el: 1,
            itensMenuInferior: []
        }
    },
    methods: {
        activeFun(el) {
            this.active_el = el;
        },
        activeHome(el) {
            this.active_el = el;
            this.$router.push('/home')
            console.log(this.active_el)
        },
        activeSol(el) {
            this.active_el = el;
            this.$router.push('/solicitacoes')
            console.log(this.active_el)
        },
        activeTrans(el) {
            this.active_el = el;
            this.$router.push('/transacoes')
            console.log(this.active_el)
        },
        activeRec(el) {
            this.active_el = el;
            this.$router.push('/recepcao')
            console.log(this.active_el)
        }
    },
    created(){
        this.itensMenuInferior = this.$store.getters.GetItens.filter(item => {
            return (item.menuinferior == true)
        })
    }
 }
</script>

<style>

</style>