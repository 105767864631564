<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
           <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">receipt</span> {{$t('reservas')}}</h5>
                <h6>{{$t('reservastitulo')}}</h6>	
            </div>
            <div class="main-container mt-5">
			
			<div v-if="possuiPromocoes" class="container mb-4">
				<div class="card bg-default">
					<div class="card-body">
						<a @click="$router.push('/promocaohotel')">
							<div class="media text-white">
								<div class="icon icon-50 bg-white text-default mr-2 rounded-circle"><i class="material-icons">local_offer</i></div>
								<div class="media-inner">
									<h5 class="font-weight-normal">{{$t('ofertaspromocoes')}}</h5>
									<p><small class="text-mute">{{$t('acessenossapagina')}}</small></p>
								</div>
								<div class="align-self-center ml-auto">
									<i class="material-icons">arrow_forward</i>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			
			
			<div class="container mb-4" v-if="temTelefoneReserva">
                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="row align-items-center ponteiro" @click="callNumber()">
                            <div class="col-auto pr-0">
                                <div class="avatar avatar-50 border-0 bg-primary rounded-circle text-white">
                                    <i class="material-icons vm text-template">phone</i>
                                </div>
                            </div>
                            <div class="col-auto align-self-center">
                                <h6 class="mb-1">{{$t('ligarreservas')}}</h6>
                                <p class="small text-secondary">{{$t('falarreservas')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			
			<div class="container mb-4" v-if="temZap">
                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="row align-items-center ponteiro" @click="direcionarZap()">
                            <div class="col-auto pr-0">
                                <div class="avatar avatar-50 border-0 bg-success rounded-circle text-success">
                                    <img src="../../img/whatsapp.svg" width="18"/>
                                </div>
                            </div>
                            <div class="col-auto align-self-center">
                                <h6 class="mb-1">{{$t('conversarwhatsapp')}}</h6>
                                    <p class="small text-secondary">{{$t('atendimentoreservaswhatsapp')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			
			<div class="container mb-4" v-if="temEmail">
                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="row align-items-center ponteiro" @click="sendEmail()">
                            <div class="col-auto pr-0">
                                <div class="avatar avatar-50 border-0 bg-info rounded-circle text-white">
                                    <i class="material-icons vm text-template">mail</i>
                                </div>
                            </div>
                            <div class="col-auto align-self-center">
                                <h6 class="mb-1">{{$t('enviaremailreservas')}}</h6>
                                <p class="small text-secondary">{{$t('falarreservas')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </main>
      <Footer />
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    components: {
         Loader, Menu, Header, Footer
    },
    data() {
        return {
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
            possuiPromocoes: true
        }
    },
    computed: {
        temEmail() {
            let email = this.$store.getters.GetLoginInfo.hotel.emailreserva?.trim()
            return (email !== null && email !== undefined && email !== '')
        },
        temZap() {
            let zap = this.$store.getters.GetLoginInfo.hotel.whatsapp?.trim()
            return (zap !== null && zap !== undefined && zap !== '')
        },
        temTelefoneReserva() {
            let tel = this.$store.getters.GetLoginInfo.hotel.telefonereserva?.trim()
            return (tel !== null && tel !== undefined && tel !== '')
        }
    },
    methods: {
        checarPromocoes() {
            axios
                .post(
                CONSTANTS.API_URL + "/fdguest/hotelpromocoes/select",
                {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idioma: this.$i18n.locale,
                    ativo: true
                },
                { headers: this.headers }
                )
                .then((response) => {
                if (response.status == 200) {
                    this.possuiPromocoes = (response.data.length > 0);
                }
                })
                .catch((err) => {
                console.log({ err_menu: err });
            });
        },
        direcionarZap() {
            var link=document.createElement("a");
            link.id = 'zapLink';
            link.href=`https://wa.me/${this.$store.getters.GetLoginInfo.hotel.whatsapp}`;
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        callNumber() {
            var link=document.createElement("a");
            link.id = 'telLink';
            link.href=`tel:${this.$store.getters.GetLoginInfo.hotel.telefonereserva}`;
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);            
        },
        sendEmail() {
            var link=document.createElement("a");
            link.id = 'emailLink';
            link.href=`mailto:${this.$store.getters.GetLoginInfo.hotel.emailreserva}`;
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    mounted() {

        if(this.$store.getters.GetLoginInfo.permitir_chamado === false){
            this.$router.push('/avaliacoes')
        }

        this.checarPromocoes();
    }

}
</script>

<style>

</style>