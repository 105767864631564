<template>
    <div>
        <!-- Logo carregando -->
        <Loader />
        <Menu />

        <!-- Begin page content -->
        <main class="flex-shrink-0 main">

            <!-- Fixed navbar -->
            <Header />

            <!-- page content start -->
            <div class="main-container">
                <div class="container">
                    <div class="card">
                        <div class="card-header border-bottom">
                            <div class="row">
                                <div class="col-auto">
                                    <div class="avatar avatar-50 bg-default-light text-default rounded">
                                        <span class="material-icons">language</span>
                                    </div>
                                </div>
                                <div class="col align-self-center pl-0">
                                    <h6 class="mb-1">{{$t('escolhaumidioma')}}</h6>
                                    <p class="text-secondary">{{$t('selecioneoidiomadepreferencia')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0 px-0">
                            <ul class="list-group list-group-flush">
                                <template v-if="idiomas && idiomas.length > 0">
                                    <li class="list-group-item" v-for="idioma in idiomas" v-bind:key="idioma.ididioma">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" name="language" class="custom-control-input ponteiro" 
                                                :id="'Switch_'+idioma.ididioma"
                                                :checked="idioma.default"
                                                @click="changeLocale(idioma)"
                                            >
                                            <label class="custom-control-label" :for="'Switch_'+idioma.ididioma">{{idioma.descidioma}}</label>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
    import axios from 'axios'
    import CONSTANTS from '../constants'
    import Loader from '../components/Loader.vue'
    import Menu from '../components/Menu.vue'
    import Header from '../components/Header.vue'
    import Footer from '../components/Footer.vue'
    export default {
        components: {
            Menu, Header, Footer, Loader
        },
        data() {
            return {
                idiomas: null,
                headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                }                
            }
        },
        methods: {
            carregarMenuItens() {
                axios.post(CONSTANTS.API_URL + '/fdguest/menu/select', {
                    idhotel : this.$store.getters.GetLoginInfo.idhotel,
                    idpai : "1",
                    idioma: this.$i18n.locale
                },  {headers: this.headers}).then(response => {
                    if (response.status == 200) {
                        this.$store.dispatch('set_Itens', response.data);
                        var primeirosItens = []
                        var demaisItens = [];
                        for (var i=0; i < response.data.length; i++) {
                            var obj = response.data[i]
                            if (i < 6)
                                primeirosItens.push(obj);
                            if (i >= 6)
                                demaisItens.push(obj);
                        }
                        this.$store.dispatch('set_Primeiros_Itens', primeirosItens);
                        this.$store.dispatch('set_Demais_Itens', demaisItens);
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });
            },        
            carregarUltimasTransacoes() {
                var data = {
                    idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                    idusuario : this.$store.getters.GetLoginInfo.idhotel,
                    numreserva : this.$store.getters.GetLoginInfo.numreserva,
                    idhospedepms: this.$store.getters.GetLoginInfo.idhospedepms,
                    opcao : "todas",
                    idioma: this.$i18n.locale
                };
                axios.post(CONSTANTS.API_URL + '/fdguest/transacao/select', data,  {
                    headers: this.headers
                })
                .then(response => {
                    if (response.status == 200) {
                        this.$store.dispatch('set_Ultimas_Transacoes', response.data.slice(0,2));
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });
            },
            changeLocale(entry) {
                // Altera o idioma na tela
                this.$i18n.locale = entry.codidioma;
                // Altera o idioma default do usuário no App
                this.$store.dispatch('set_Default_Language', entry.codidioma);
                // Altera o idioma default do usuário no banco
                axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/default/hospede/set', {
                    idreservasfront: this.$store.getters.GetLoginInfo.idreservasfront,
                    idioma : entry.codidioma
                }, {headers: this.headers}).then(response => {
                    if (response.status == 200) {
                        // É preciso recarregar em caso de mudança de idioma
                        this.carregarUltimasTransacoes();
                        this.carregarMenuItens();
                        // this.carregarNotificacoes();
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });                
            },
            carregarIdiomas() {
                var headers = {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                }
                axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
                    tipo: 'H',
                    idreservasfront: this.$store.getters.GetLoginInfo.idreservasfront
                }, {headers: headers})
                .then(response => {
                    try {
                        if (response.status == 200) {
                            if (response.data) {
                                this.idiomas = response.data;
                            }
                        }
                    }
                    catch(e) {
                        console.log(e);
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });                
            }
        },
        mounted() {
            this.carregarIdiomas();
        }
    }
</script>

<style>

</style>