<template>
  <div>
      <Loader />
      <main class="flex-shrink-0 main has-footer">
           <header class="header">
            <div class="row">
                <div class="col-auto px-0">
                    <button class="btn btn-40 btn-link back-btn" type="button" @click="$router.push('/home')">
                        <span class="material-icons">keyboard_arrow_left</span>
                    </button>
                </div>
                <div class="text-left col align-self-center">
                    <a class="navbar-brand" >
                        <h5 class="mb-0">Pergunta</h5>
                    </a>
                </div>
            </div>
        </header>
            <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="mb-4"><span class="material-icons icon">done</span>Perguntas</h5>
            </div>
            <div class="main-container">
                <div class="container mb-2">
                    <div class="row">
                        <div  class="col-12 col-md-6 mt-3">
                             <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h6 class="mb-1">O que achou do nosso wifi? </h6>
										<ul class="list-group list-group-flush">
											<li class="list-group-item">
												<label class="form-control-label">Resposta</label>
											</li>
											<li class="list-group-item">
                                                <button class="btn btn-40 btn-red">
                                                    <span class="material-icons pr-1">
                                                    sentiment_very_dissatisfied
                                                    </span>
                                                </button>
                                                <button class="btn btn-40 btn-orange">
                                                    <span class="material-icons pr-1">
                                                    sentiment_dissatisfied
                                                    </span>

                                                </button>
                                                <button class="btn btn-40 btn-yellow">
                                                    <span class="material-icons pr-1">
                                                    sentiment_neutral
                                                    </span>

                                                </button>
                                                <button class="btn btn-40 btn-blue">
                                                    <span class="material-icons pr-1">
                                                    sentiment_satisfied_alt
                                                    </span>

                                                </button>
                                                <button class="btn btn-40 btn-green">
                                                    <span class="material-icons">
                                                    sentiment_very_satisfied
                                                    </span>

                                                </button>
											</li>
										</ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>

                </div>

            </div>
      </main>
     
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
export default {
     components: {
            Loader,
        },
}
</script>

<style scoped>
    .material-icons {
        font-size: 48px;
        
    }
    .btn.btn-40 {
        height: 40px;
        line-height: 0px;
        width: 40px;
        padding-right: 50px;
        
    }

    .btn.btn-red {
        color: red;
    }
    .btn.btn-orange {
        color: orange;
    }
    .btn.btn-yellow {
        color: yellow;
    }
    .btn.btn-blue {
        color: blue;
    }
    .btn.btn-green {
        color: green;
    }
</style>