import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n';
import FlagIcon from 'vue-flag-icon';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/Sao_Paulo')

// Redirecionar para o login quando houver erro de autenticação ou acesso.
axios.interceptors.response.use(
  function(successRes) {
    null;
    return successRes;
  }, 
  function(error) {
    // Se for erro de autenticação e não estiver na tela de login, direcionar...
    if ((error.message.includes('401') || error.message.includes('403'))
      && window.location.pathname != '/') {
        console.error('Erro de autenticação! Direcionar para login...');
        sessionStorage.clear();
        document.location.href="/";
    // } else {
    //   console.error(`Outro erro: ${JSON.stringify(error)}`);
    }
    throw error;
  }
);

const { snack, snackE, snackW } = require('./snack')
Vue.prototype.$snack = snack
Vue.prototype.$snackE = snackE
Vue.prototype.$snackW = snackW
Vue.prototype.$d = dayjs

Vue.use(FlagIcon)
Vue.config.productionTip = false
Vue.prototype.$http = axios

import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad);

Vue.filter('formatarMoeda', function(value) {
  if (value) {
      var v = parseFloat(value).toFixed(2);
      v = v.replace(".", ",");
      v = v.replace(/,(\d)\b/g, ",$10");
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
      return v;
  } else {
      return '0,00'
  }
})

Vue.filter('formatarDesconto', function(value) {
  if (value) {
      var v = parseFloat(value).toFixed(0);
      v = v.replace(".", ",");
      v = '-' + v + '%'
      return v;
  } else {
      return '0%'
  }
})

import VueSocketIO from 'vue-socket.io';
import SocketIO from "socket.io-client";
import CONSTANTS from './constants';
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketIO(CONSTANTS.API_URL + '/fdg', { query: {}, autoConnect: false }),
    vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_",
    },
  })
);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
