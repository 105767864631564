<template>
  <div>
    <Loader />
    <div class="backdrop"></div>
    <main class="flex-shrink-0 main has-footer">
      <header class="header">
        <div class="row">
          <div class="col-auto px-0">
            <button
              class="btn btn-40 btn-link back-btn"
              type="button"
              @click="$router.push('/concierge')"
            >
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
          </div>
          <div class="text-left col align-self-center">
            <a class="navbar-brand">
              <h5 class="mb-0">Promoção</h5>
            </a>
          </div>
        </div>
      </header>
      <div class="container mb-4 px-0">
        <!-- Swiper -->
        <Slider :inView="4" type="html" v-if="categorias.length > 0">
          <div
            class="card-slider"
            v-for="(categoria, idx) in categorias"
            :key="idx"
          >
            <div :id="'filtro' + categoria.idcategoria" class="card-body">
              <div
                class="
                  avatar avatar-60
                  rounded
                  mb-1
                  bg-default-light
                  text-default
                "
              >
                <span
                  @click="searchInfo(categoria.idcategoria)"
                  class="material-icons"
                  >{{ categoria.icone }}</span
                >
              </div>
              <p
                @click.self="searchInfo(categoria.idcategoria)"
                class="text-secondary"
              >
                <small>{{ categoria.descricao }}</small>
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div class="main-container" v-if="promocoes && promocoes.length > 0">
        <div class="container">
          <!-- ordenar -->
          <!-- <div class="swiper-container categories2tab1 text-center mb-4">
            <p class="text-left small">Ordernar por:</p>
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <button class="btn btn-sm btn-default active rounded mr-1">
                  Preço
                </button>
              </div>
              <div class="swiper-slide">
                <button class="btn btn-sm btn-outline-default rounded mr-1">
                  Data
                </button>
              </div>
              <div class="swiper-slide">
                <button class="btn btn-sm btn-outline-default rounded mr-1">
                  Desconto
                </button>
              </div>
              <div class="swiper-slide">
                <button class="btn btn-sm btn-outline-default rounded mr-1">
                  Nome
                </button>
              </div>
              <div class="swiper-slide">
                <button class="btn btn-sm btn-outline-default rounded mr-1">
                  Marca
                </button>
              </div>
            </div>

            <div
              class="swiper-pagination white-pagination text-left mb-3"
            ></div>
          </div> -->

          <div class="row">
            <!-- produto -->
            <div class="col-12 my-2"
              v-for="(promocao, idx) in promocoes" :key="idx"
            >
              <!-- <div class="card border-0 mb-4 overflow-hidden">
                <div class="card-body h-150 position-relative">
                  <div class="bottom-left m-2">
                    <button class="btn btn-sm btn-default rounded">{{promocao.desconto}}</button>
                  </div>
                  <a @click="irProduto(promocao.idpromocao)" class="background">
                    <img src="" alt="" />
                  </a>
                </div>
                <div class="card-body">
                  <p class="mb-0">
                    <small class="text-secondary">{{ promocao.nome }}</small>
                  </p>
                  <a @click="irProduto(promocao.idpromocao)">
                    <p class="mb-0">{{ promocao.descricao }}</p>
                  </a>
                  <h5 class="mb-0">R${{ promocao.valor }}</h5>
                </div>
              </div> -->
              <div class="ponteiro">
                <promocao-parceiro-card :promocao="promocao"  @ir="irProduto(promocao.idpromocao)"></promocao-parceiro-card>
              </div>
            </div>
          </div>
          <!-- <div class="row text-center">
            <div class="col-6 col-md-4 col-lg-3 mx-auto">
              <button class="btn btn-sm btn-outline-secondary rounded">
                Ver mais...
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <div v-if="promocoes && promocoes.length == 0">
        <div class="container mb-1 mt-3 text-center text-white">
          <h5 class="mb-4">
            <span class="material-icons icon">receipt</span>
            {{ $t("sempromocaotitulo") }}
          </h5>
          <h6>{{ $t("sempromocaotexto") }}</h6>
        </div>
      </div>      
    </main>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import CONSTANTS from "../constants";
import axios from "axios";
import Slider from "./Slider.vue";
import PromocaoParceiroCard from './PromocaoParceiroCard.vue';

export default {
  components: {
    Loader,
    Slider,
    PromocaoParceiroCard,
  },
  data() {
    return {
      promocoes: [],
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      parceiros: [],
      categorias: [],
      items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    };
  },
  methods: {
    searchPromocoes() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/promocao/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
            idparceiro: this.$store.getters.GetIdParceiro,
            ativo: true
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.promocoes = response.data;
            console.log(this.promocoes);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
    searchInfo(idcategoria) {
      var param = {
        idhotel: this.$store.getters.GetLoginInfo.idhotel,
        idcategoria: idcategoria,
        idioma: this.$i18n.locale,
        ativo: true
      };
      for (var i = 0; i < this.categorias.length; i++) {
        var cat = this.categorias[i];
        var m = document.getElementById("filtro" + cat.idcategoria).style;
        if (cat.idcategoria != idcategoria) {
          m.opacity = 1;
        }
      }
      var s = document.getElementById("filtro" + idcategoria).style;
      if (s.opacity == 0.5) {
        s.opacity = 1;
        delete param.idcategoria;
      } else {
        s.opacity = 0.5;
      }

      axios
        .post(CONSTANTS.API_URL + "/fdguest/promocao/select", param, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.promocoes = response.data;
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },

    searchCategorias() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/categoria/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.categorias = response.data;
            console.log(this.categorias);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
    irProduto(idpromocao) {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/promocao/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idpromocao: idpromocao,
            idioma: this.$i18n.locale,
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data&&response.data.length>0) {
              console.log(JSON.stringify(response.data[0]));
              this.$store.dispatch("set_Current_produto", response.data[0]);
              this.$router.push("/produtos");
            }
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
  },
  mounted() {
    this.searchPromocoes();
    this.searchCategorias();
  },
};
</script>

<style>
</style>