<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
           <div class="container-fluid px-0">
            <div class="card overflow-hidden">
                <div class="card-body p-0 h-150">
                    <div class="background">
                        <img src="../../img/esmeraldahotel.png" alt="" style="display: none;">
                    </div>
                </div>
            </div>
        </div>
         <div class="container mb-4 text-center text-white">
            <h4 class="mb-4  mt-4">Esmeralda Hotel</h4>
        </div>
        <div class="main-container">
            <div class="container mb-4">
                <div class="row">
                    <div class="col-12 col-md-6 mt-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                       
                                        <p class="text-secondary">
										O Esmeralda Praia Hotel possui uma infraestrutura inovadora com classificação
										4 estrelas superior. Localizado próximo aos principais shoppings, restaurantes,
										bares, pubs, feiras de artesanato e centro de convenções. O hotel ainda conta
										com uma localização privilegiada à beira mar da área mais nobre de Ponta
										Negra, oferecendo uma deslumbrante vista para o morro do careca,
										cartão postal da Cidade do Sol.<br/>
										O Esmeralda Praia Hotel garantirá todo conforto que você merece tornando
										sua viagem em um sonho inesquecível.
										</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					
					
                </div>
            </div>
 
        </div>
      </main>
      <Footer />
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
    components: {
         Loader, Menu, Header, Footer
    },
    data() {
        return {

        }
    }

}
</script>

<style>

</style>