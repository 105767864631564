<template>
	<div v-if="grupos && grupos.length>0">
		<!-- Grupos de Produtos -->
		<div v-for="(grupo,index) in grupos" :key="index" 
			:id="'accordion-group-'+index" class="accordion accordion-primary">
			<div class="accordion__item my-1">
				<div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#collapse-group-'+index">
					<span class="accordion__header--text">{{grupo.desccategoriaprod}} (R$)</span>
					<span class="accordion__header--indicator"></span>
				</div>
				<div :id="'collapse-group-'+index" class="collapse" :data-parent="'#accordion-group-'+index">
					<div class="accordion__body--text">
						<produtos-itens :produtos="grupo.produtos"
							@change="$emit('change')"
						></produtos-itens>
					</div>
				</div>
			</div>
		</div>
		<div class="btn-primary rounded p-2">
			<div class="row">
				<div class="col-6">
					{{$t('total')}} (R$)
				</div>
				<div class="col-6 text-right">
					{{totalprodutos | formatarMoeda}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProdutosItens from './ProdutosItens.vue'
export default {
	props: [
		'grupos', 'iditem'
	],
    components: {
        ProdutosItens
    },
    data() {
        return {

        }
	},
	computed: {
		totalprodutos() {
			var result = 0
			for (var i=0; i < this.grupos.length; i++) {
				for (var j=0; j < this.grupos[i]['produtos'].length; j++) {
					var item = this.grupos[i]['produtos'][j]
					result += parseFloat(item.precoimportado)
						* parseInt(item.qtde)
				}
			}
			return result
		}
	},
	methods: {

	},
	mounted() {

	}
}
</script>

<style>

</style>