<template>
  <div>
      <Loader />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
            <header class="header">
            <div class="row">
                <div class="col-auto px-0">
                    <button @click="$router.push('/restaurante')" class="btn btn-40 btn-link back-btn" type="button">
                        <span class="material-icons">keyboard_arrow_left</span>
                    </button>
                </div>
                <div class="text-left col align-self-center">
                    <a class="navbar-brand" href="/restaurante">
                        <h5 class="mb-0">{{$t('cafedamanha')}}</h5>
                    </a>
                </div>
            </div>
        </header>
        <div class="main-container">
				<!-- <div class="col-12 col-md-6 mt-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col">
									<h6 class="mb-1">Café Continental:</h6>
									<ul class="list-group list-group-flush">
										<div class="input-group cart-count cart-count-lg rounded mt-3 mb-4 ml-3">
											<div class="input-group-prepend">
												<button @click="cafe1 --" class="btn btn-outline-secondary rounded" type="button">-</button>
											</div>
											<input type="text" class="form-control rounded" placeholder="" :value="cafe1">
											<div class="input-group-append">
												<button @click="cafe1 ++" class="btn btn-outline-secondary rounded" type="button">+</button>
											</div>
										</div>
										
										<li class="list-group-item">
											<label class="form-control-label">Informação adicional:</label>
											<textarea v-model="cafe1text" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
										</li>
										
										<li class="list-group-item">
											<button @click="solCafe1()"  class="btn btn-block btn-default rounded mr-3">Confirmar Solitação</button>
										</li>
										<li class="list-group-item">
											<div class="cafeClass">
												{{$t('atencaocafe')}}
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<sol-control :setor="1" :tipos="'10'"></sol-control>
				<!-- <div class="col-12 col-md-6 mt-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col">
									<h6 class="mb-1">Café Americano:</h6>
									<ul class="list-group list-group-flush">
										<div class="input-group cart-count cart-count-lg rounded mt-3 mb-4 ml-3">
											<div class="input-group-prepend">
												<button  @click="cafe2 --" class="btn btn-outline-secondary rounded" type="button">-</button>
											</div>
											<input type="text" class="form-control rounded" placeholder="" :value="cafe2">
											<div class="input-group-append">
												<button  @click="cafe2 ++" class="btn btn-outline-secondary rounded" type="button">+</button>
											</div>
										</div>
										
										<li class="list-group-item">
											<label class="form-control-label">Informação adicional:</label>
											<textarea v-model="cafe2text" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
										</li>
										
										<li class="list-group-item">
											<button @click="solCafe2()" class="btn btn-block btn-default rounded mr-3">Confirmar Solitação</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div> -->
        </div>
      </main>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
import SolControl from './SolControl.vue'

export default {
    components: {
         Loader,
        SolControl,
    },
    data() {
        return {
			cafe1:'1',
			cafe1text: '',
			cafe2: '1',
			cafe2text: '',
			headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
        }
	},
	methods: {
		solCafe1() {
            var str = this.$store.getters.GetLoginInfo.nome;

            var res = str.split(" ");

            var nome = res[0];

			var sobrenome = (res.length==1)?'':res[res.length-1];

            var cafe1 = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : this.$store.getters.GetCurrentItem.idhotlinesetores,
                "idhotel" :  this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
				"idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" : `${this.$t('gostariasolicitar')} ${this.cafe1} do Café da manha tipo 1. Obs: ${this.cafe1text}`,
				"idtiposolicitacao" : CONSTANTS.TIPO.CAFE
            };
            console.log(JSON.stringify(cafe1))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
            cafe1, {headers: this.headers}).then(response => {
                if(response.status == 200) {
					this.$router.push("/sucesso")
                }
            })
		},
		solCafe2() {
            var str = this.$store.getters.GetLoginInfo.nome;

            var res = str.split(" ");

            var nome = res[0];

			var sobrenome = (res.length==1)?'':res[res.length-1];

            var cafe2 = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : this.$store.getters.GetCurrentItem.idhotlinesetores,
                "idhotel" :  this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
				"idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" : `${this.$t('gostariasolicitar')}` + this.cafe2 + ' do Café da manha tipo 2, observações ' + this.cafe2text,
				"idtiposolicitacao" : CONSTANTS.TIPO.CAFE
            };
           
           
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
            cafe2, {headers: this.headers}).then(response => {
                if(response.status == 200) {
					this.$router.push("/sucesso")
                }
            })
        },
	}

}
</script>

<style scope>
.cafeClass {
    color: red;
}
</style>