<template>

    <div id="sucess" class="body-scroll d-flex flex-column h-100 menu-overlay" data-page="alerta">
        <Loader />

       <main class="flex-shrink-0 main">

        <!-- page -->
            <div class="main-container h-50">
                <div class="container h-100">
                    <div class="row h-100">
                        <div class="col-12 col-md-6 col-lg-4 align-self-center text-center my-3 mx-auto">
                            <div class="icon icon-120 bg-danger-light text-danger rounded-circle mb-3">
                                <i class="material-icons display-4">close</i>
                            </div>
                            <h2>{{$t('naoconcluido')}}</h2>
                            <h6 class="text-secondary mb-3">{{$t('solicitacaonaoenviada')}}</h6>
                            <p class="text-secondary">{{$t('facanovasolicitacao')}} </p>
                              <p class="text-secondary">{{$t('direcionadopaginainicial')}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Loader from '../components/Loader.vue'
export default {
     components: {
         Loader, 
    },
    data() {
        return {

        }
    },
    mounted() {
            setTimeout(() =>{
                this.$router.push("/home")
            }, 4000)
			}
    // }
}
</script>

<style>

</style>