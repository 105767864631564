<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
            <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="mb-4"><span class="material-icons icon">account_balance_wallet</span> {{$t('transacoes')}}</h5>
                <h6>{{$t('acompanhetodosastransacoesfinanceirasrealizadasduranteasuahospedagem')}}</h6>	
            </div>
            <div class="main-container mt-5">
			
                <div class="main-container">
                    <div class="container mb-1">
                        
                        <div v-for="trans in transacoes" :key="trans.idtransacao" class="card border-0 mb-4">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-auto pr-0">
                                        <div class="avatar avatar-50 border-0 bg-default-light rounded-circle text-default">
                                            <i class="material-icons vm text-template">local_atm</i>
                                        </div>
                                    </div>
                                    <div class="col-4 align-self-center">
                                        <h6 class="mb-1">{{trans.descricao}}</h6>
                                        <p class="small text-secondary">{{trans.data}}</p>
                                    </div>
                                    <div class="col-auto align-self-center border-left">
                                        <h6 class="mb-1">{{trans.valor}}</h6>
                                        <p class="small text-secondary">{{$t('quarto')}}: {{trans.uh}}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        
                    </div>
                
                    <div class="container mb-4">
                        <div class="row my-3 h6 font-weight-normal">
                            <div class="col">{{$t('subtotal')}}</div>
                            <div class="col text-right text-mute">R$ 300,00</div>
                        </div>
                        <div class="row my-3 h6 font-weight-normal">
                            <div class="col">{{$t('desconto')}}</div>
                            <div class="col text-right text-mute">R$ 20.00</div>
                        </div>
                        <hr>
                        <div class="row h6 font-weight-bold">
                            <div class="col">{{$t('totalapagar')}}</div>
                            <div class="col text-right text-mute">R$ 280,00</div>
                        </div>
                    </div>

                </div>
			
           
            </div>
      </main>
      <Footer :itemdestacar="4"/>
  </div>
</template>

<script>
    import CONSTANTS from '../constants'
    import axios from 'axios'
    import Loader from '../components/Loader.vue'
    import Menu from '../components/Menu.vue'
    import Header from '../components/Header.vue'
    import Footer from '../components/Footer.vue'
    export default {
        components: {
            Loader, Menu, Header, Footer
        },
        data() {
            return {
                headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
                transacoes: []
            }
        },
        methods: {
            carregarTransacoes() {
                var data = {
                    idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                    idusuario : this.$store.getters.GetLoginInfo.idhotel,
                    numreserva : this.$store.getters.GetLoginInfo.numreserva,
                    idhospedepms: this.$store.getters.GetLoginInfo.idhospedepms,
                    opcao : "todas",
                    idioma: this.$i18n.locale
                };
                axios.post(CONSTANTS.API_URL + '/fdguest/transacao/select', data,  {headers: this.headers})
                .then(response => {
                    if (response.status == 200) {
                        this.transacoes = response.data;
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });
            },
        },
        mounted(){
            this.carregarTransacoes();
            window.scrollTo(0,0); // Vai pro topo da página
        }

    }
</script>

<style>

</style>