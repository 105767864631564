<template>
	<div>
		<VueSignaturePad width="100%" height="30vh" ref="signaturePad" style="background:white" saveType="image/png" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
		<div class="col-md-12 dense">
			<hr id="linha_assinatura" />
				<center><small>{{nome}}</small></center>
			<br />
		</div>
		<div class="row">
			<div class="col-2">
			</div>
			<div class="col-4 text-center">
				<div class="btn-success rounded p-1" v-on:click.stop.prevent="confirmar_assinatura()">
					{{ $t('ok') }}
					<i class="material-icons vm text-white">check</i>
				</div>
			</div>
			<div class="col-4 text-center">
				<div class="btn-primary rounded small py-1" v-on:click.stop.prevent="limpar_assinatura()">
					{{ $t('apagar') }}
					<i class="material-icons vm text-white">delete</i>
				</div>
			</div>
			<div class="col-2">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'nome', 'sobrenome'
	],
    components: {
        
    },
    data() {
        return {
			headers: {
				'x-access-token': this.$store.getters.GetLoginInfo.token
			}
        }
	},
	methods: {
		limpar_assinatura() {
			//this.$refs.signaturePad.undoSignature();
			this.$refs.signaturePad.clearSignature();
			this.$emit('limpar')
		},
		confirmar_assinatura() {
			let objetoAssinatura = this.$refs.signaturePad.saveSignature()
			if(!objetoAssinatura.isEmpty){
				let img = objetoAssinatura.data.split(',')[1]
				if (!img.length > 0) {
					console.log("Imagem invalida");
					return;
				}
				this.$emit('confirmar',img)
			}
		}
	},
	computed: {
		assinaturaValida() {
			let { isEmpty } = this.$refs.signaturePad.saveSignature();
			if (isEmpty) {
				console.error("A assinatura é obrigatoria")
				return false
			}
			return !isEmpty
		}
	},
	mounted() {

	}
		

}
</script>

<style>

</style>