import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Perfil from '../components/Perfil.vue'
import Recepcao from '../components/Recepcao.vue'
import Concierge from '../components/Concierge.vue'
import Reservas from '../components/Reservas.vue'
import PromocaoParceiro from '../components/PromocaoParceiro.vue'
import PromocaoHotel from '../components/PromocaoHotel.vue'
import Produtos from '../components/Produtos.vue'
import Restaurante from '../components/Restaurante.vue'
import Cafe from '../components/Cafe.vue'
import Gerencia from '../components/Gerencia.vue'
import Hotel from '../components/Hotel.vue'
import Sobre from '../components/Sobre.vue'
import Manutencao from '../components/Manutencao.vue'
import Salao from '../components/Salao.vue'
import Avaliacoes from '../components/Avaliacoes.vue'
import Solicitacoes from '../components/Solicitacoes.vue'
import Transacoes from '../components/Transacoes.vue'
import Notificacoes from '../components/Notificacoes.vue'
import NotificacoesDetalhe from '../components/NotificacoesDetalhe.vue'
import Governanca from '../components/Governanca.vue'
import Idioma from '../components/Idioma.vue'
import Sucesso from '../components/Sucesso.vue'
import Erro from '../components/Erro.vue'
import Resposta from '../components/Resposta.vue'
import Respostas from '../components/Respostas.vue'
import Cidade from '../components/Cidade.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/login/:id', name: 'LoginAvalId', component: Login },
  { path: '/login/:id/:flag', name: 'AppAvalId', component: Login },
  { path: '/home', name: 'Home', component: Home },
  { path: '/perfil', name: 'Perfil', component: Perfil },
  { path: '/home', component: Home },
  { path: '/recepcao', component: Recepcao },
  { path: '/concierge', component: Concierge },
  { path: '/reservas', component: Reservas },
  { path: '/promocaoparceiro', component: PromocaoParceiro },
  { path: '/promocaohotel', component: PromocaoHotel },
  { path: '/produtos', component: Produtos },
  { path: '/restaurante', component: Restaurante },
  { path: '/cafe', component: Cafe },
  { path: '/gerencia', component: Gerencia },
  { path: '/hotel', component: Hotel },
  { path: '/sobre', component: Sobre },
  { path: '/manutencao', component: Manutencao },
  { path: '/salao', component: Salao },
  { path: '/avaliacoes', component: Avaliacoes },
  { path: '/solicitacoes', component: Solicitacoes },
  { path: '/transacoes', component: Transacoes },
  { path: '/notificacoes', component: Notificacoes },
  { path: '/notificacoesdetalhe', component: NotificacoesDetalhe },
  { path: '/governanca', component: Governanca },
  { path: '/idioma', component: Idioma },
  { path: '/sucesso', component: Sucesso },
  { path: '/error', component: Erro },
  { path: '/resposta', component: Resposta },
  { path: '/resposta/:idavaliacao', component: Resposta },
  { path: '/respostas', component: Respostas },
  { path: '/cidade', component: Cidade },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
