<template>
    <div style="width:100%;">
        <div class="ponteiro es-reload es-message-start" @click="carregarHistorico()">
            Recarregar
            <span class="material-icons icon icone-filtro ponteiro">refresh</span>
        </div>
        <div class="es-timeline">
            <div v-for="(item,index) in historico" :key="index">
                <div :class="{ 'es-timeline-message left' : item.hospede, 'es-timeline-message right' : !item.hospede }">
                    <div class="es-timeline-text p-1 text-left"><strong>{{item.texto}}</strong></div>
                    <!-- <div v-if="item.produtos&&item.produtos.length>0">
                        <solicitacao-produto-historico
                            :produtos="item.produtos" :idevento="item.idevento"
                        ></solicitacao-produto-historico>
                    </div> -->
                </div>
                <div class="es-message-end">
                    <div :class="{ 'es-timeline-name left' : item.hospede, 'es-timeline-name right' : !item.hospede }">
                        {{item.nome}} - {{formatarData(item.dataevento)}}
                    </div>
                </div>
            </div>
            <div v-if="semregistro">
                Histórico sem registros.
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import moment from 'moment'
// import SolicitacaoProdutoHistorico from './SolicitacaoProdutoHistorico.vue'

export default {
//   components: { SolicitacaoProdutoHistorico },
    props: {
        idhotlinesolicitacoes: {
            type: Number,
            required: true
        }
    },    
    data () {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            historico: [],
            semregistro: false
        }
    },
    methods: {
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('L HH:MM');
        },
        reload() {
            this.carregarHistorico()
        },
        carregarHistorico() {
            this.historico = []
            var  params = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                usuario: this.$store.getters.GetLoginInfo.idusuario,
                idhotlinesolicitacoes: this.idhotlinesolicitacoes
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/historico',
                    params, { headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    if (response.data.length > 0) {
                        this.historico = response.data
                        this.semregistro = false
                    } else {
                        this.semregistro = true
                    }
                } else {
                    console.log(JSON.stringify(response))
                }
            })            
        }
    },
    created() {
        this.carregarHistorico()
    }
}
</script>
<style>
.es-timeline{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 450px;
    margin: auto;
    background-color: azure;
    border-radius: 15px 15px 15px 15px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.es-timeline-message {
    flex-direction: row;
    text-align: center;
    background-color: rgb(123, 188, 248);
    margin: 0rem;
    padding: 0.5rem;
    width: 100%;
}
.es-timeline-message.left {
    width: 95%;
    text-align: left;
    border-radius: 15px 15px 15px 0px;
    box-shadow: -10px 10px 5px grey;
    margin-left: 10px;
}
.es-timeline-message.right {
    width: 95%;
    text-align: right;
    border-radius: 15px 15px 0px 15px;
    box-shadow: 10px 10px 5px grey;
}
.es-timeline-text {
    white-space: pre-wrap!important;
    font-weight: normal;
    color: rgb(24, 22, 22);
    margin: 0;
}
.es-timeline-name {
    color: rgb(88, 88, 88);
    font-size: 0.7rem;
    font-weight: bold;
    padding-right: 1.0rem;
    padding-left: 1.0rem;
}
.es-timeline-time {
    color: rgb(54, 54, 54);
    font-size: 0.7rem;
    font-weight: bold;
    padding-right: 1.0rem;
    padding-left: 1.0rem;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.es-reload {
    text-align: center;
    color: black;
    margin-bottom: 0.5rem;
}
.es-message-start {
    margin-top: 1.0rem;
}
.es-message-end {
    margin-top: 0.3rem;
    margin-bottom: 1.5rem;
}
</style>