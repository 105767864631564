<template>
  <div>
    <Loader />
    <div class="backdrop"></div>
    <main class="flex-shrink-0 main has-footer">
      <header class="header">
        <div class="row" @click="$router.push('/reservas')">
          <div class="col-auto px-0">
            <button
              class="btn btn-40 btn-link back-btn"
              type="button"
            >
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
          </div>
          <div class="text-left col align-self-center">
            <a class="navbar-brand">
              <h5 class="mb-0">{{$t('voltar')}}</h5>
            </a>
          </div>
        </div>
      </header>
      <div class="main-container" v-if="promocoes && promocoes.length > 0">
        <div class="container">
          <div v-for="(item, idx) in promocoes" :key="idx">
            <promocao-card :promocao="item"></promocao-card>
          </div>
        </div>
      </div>
      <div v-if="promocoes && promocoes.length == 0">
        <div class="container mb-1 mt-3 text-center text-white">
          <h5 class="mb-4">
            <span class="material-icons icon">receipt</span>
            {{ $t("sempromocaotitulo") }}
          </h5>
          <h6>{{ $t("sempromocaotexto") }}</h6>
        </div>
      </div>      
    </main>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import CONSTANTS from "../constants";
import axios from "axios";
import PromocaoCard from "./PromocaoCard.vue"

export default {
  components: {
    Loader, PromocaoCard
  },
  data() {
    return {
      promocoes: [],
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      produto: [],
      parceiros: [],
      categorias: [],
      items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      imagemSrc: null,
      imagemTipo: null
    };
  },
  methods: {
    carregarPromocoes() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/hotelpromocoes/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
            ativo: true
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.promocoes = response.data;
            console.log(this.promocoes);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
  },
  mounted() {
    this.carregarPromocoes();
  },
};
</script>

<style>
</style>