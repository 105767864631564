<template>
    <div>
        <Loader />
        <Menu />
        <div class="backdrop"></div>
        <main class="flex-shrink-0 main has-footer">
        <Header />
        <div class="container mb-1 mt-3 text-center text-white">
            <h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">notifications_active</span> {{$t('recepcao')}}</h5>
        </div>
        <div class="main-container">
            <!-- Ítens Cadastrados para o setor Recepção -->
            <sol-control :setor="2"></sol-control>
        </div>
        </main>
        <Footer :itemdestacar="2"/>
    </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import SolControl from './SolControl.vue'
export default {
    components: {
        Loader, Menu, Header, Footer, SolControl
    },
    data() {
        return {
            horaAcordar: '',
            mensageiro: '',
            horaLateCheck: '16:00',
        }
    },
    methods: {
        
    },
    mounted() {
        if(this.$store.getters.GetLoginInfo.permitir_chamado === false){
            this.$router.push('/avaliacoes')
        }
    }
}
</script>

<style>

</style>