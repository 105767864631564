<template>
    <div class="card border-0 mb-4 overflow-hidden" @click="abrirLink(promocao)">
      <div class="card-header">
        <h4>{{promocao.titulo}}</h4>
      </div>
      <div class="card-body">
        <div>
          <img style="width:100%;" v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
            :id="'imgPromoParceiro_' + promocao.idhotelpromocoes"
          />
        </div>
      </div>
      <div class="card-footer">
        <h5>{{promocao.subtitulo}}</h5>
      </div>
    </div>
</template>

<script>
import CONSTANTS from "../constants";
import axios from "axios";

export default {
  props: {
    promocao: {
        type: Object,
        required: true,
    }
  },    
  data() {
    return {
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      imagemSrc: null,
      imagemTipo: null
    };
  },
  methods: {
		carregarImagem() {
      if (this.promocao.imagem) {
        this.imagemSrc = null
        this.imagemTipo = null
        axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
            { id: this.promocao.imagem }, { headers: this.headers })
        .then((response) => {
            if (response.status == 200) {
                var imgObj = response.data[0]
                if (imgObj) {
                    this.imagemSrc = Buffer.from(imgObj.dados).toString()
                    this.imagemTipo = imgObj.tipo
                }
            }
        })
        .catch((e) => {
            console.error(e);
        });
      }
		},    
    abrirLink(promo) {
			if (promo.link) {
				var url = new String(promo.link)
				if (url.substring(0,4) != 'http') {
					url = 'http://' + url
				}
				window.open(url,promo.titulo)
			}
    }
  },
  mounted() {
    this.carregarImagem()
  },
};
</script>

<style>
</style>