<template>
    <div class="card" :class="{'bg-info': index%2==0, 'bg-default': index%2!=0,}">
        <div class="card-header">
            <h4 class="text-mute pt-3">{{cardCidadeSobre.titulo}}</h4>
            <div class="text-center">
                <div class="col-12 py-2">
                    <img v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
                        :id="'imgCidadeSobre_'+cardCidadeSobre.idcidadesobre"
                        width="100%" style="border-radius:15px;"
                    />
                </div>
            </div>
            <hr>
        </div>
        <div class="card-body py-0">
            <h5>{{cardCidadeSobre.subtitulo}}</h5>
            <span>{{cardCidadeSobre.texto}}</span>
            <hr>
            <h6 class="text-blue">{{cardCidadeSobre.rodape}}</h6>
        </div>
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'

export default {
    props: {
        cardCidadeSobre: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            imagemSrc: null,
            imagemTipo: null
        }
    },
    methods: {
		carregarImagem() {
            if (this.cardCidadeSobre.imagem) {
                this.imagemSrc = null
                this.imagemTipo = null
                axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: this.cardCidadeSobre.imagem }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this.imagemSrc = Buffer.from(imgObj.dados).toString()
                            this.imagemTipo = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},
    },
    mounted() {
        this.carregarImagem()
    }
}
</script>

<style>

</style>