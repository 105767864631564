<template>
<div class="main">
    <!-- Perguntas -->
    <div class="fundo center" v-show="!concluido&&!reviews">
        <div class="container">
            <!-- Ícone da Categoria de pergunta -->
            <div class="row" v-if="perguntaAtual">
                <div class="box">
                    <div>
                        <!-- <i :class="perguntaAtual.iconecategoria"></i> -->
                        <span class="material-icons icon">{{perguntaAtual.iconecategoria}}</span>
                    </div>
                </div>
            </div>
            <!-- Texto da pergunta -->
            <div class="row" style="padding: 5px;" v-if="perguntaAtual">
                <div class="box">
                <div><h3 style="text-align:center;">{{perguntaAtual.textopergunta}}</h3></div>
                </div>
            </div>
            <!-- Respostas possíveis -->
            <div class="row" v-if="perguntaAtual">
                <div class="box">
                    <div style="text-align:center;" class="ponteiro" @click="inserirResposta(1)" :class="getSelectedClass(1)">
                        <div :class="getPerguntaClass(1)"></div>
                        <div style="text-align:center;">{{$t('resposta.ruim')}}</div>
                    </div>
                    <div class="ponteiro" @click="inserirResposta(2)" :class="getSelectedClass(2)">
                        <div :class="getPerguntaClass(2)"></div>
                        <div style="text-align:center;">{{$t('resposta.regular')}}</div>
                    </div>
                    <div class="ponteiro" @click="inserirResposta(3)" :class="getSelectedClass(3)">
                        <div :class="getPerguntaClass(3)"></div>
                        <div style="text-align:center;">{{$t('resposta.bom')}}</div>
                    </div>
                    <div class="ponteiro" @click="inserirResposta(4)" :class="getSelectedClass(4)">
                        <div :class="getPerguntaClass(4)"></div>
                        <div style="text-align:center;">{{$t('resposta.otimo')}}</div>
                    </div>
                    <div class="ponteiro" @click="inserirResposta(5)" :class="getSelectedClass(5)">
                        <div :class="getPerguntaClass(5)"></div>
                        <div style="text-align:center;">{{$t('resposta.excelente')}}</div>
                    </div>
                </div>
            </div>
            <!-- Link para resposta textual -->
            <div class="row" v-if="perguntaAtual">
                <div class="box ponteiro">
                    <div class="textLink" :class="{'none': perguntaAtual.nota==null, 'block': perguntaAtual.nota != null}"
                    style="padding: 5px;" id="toggleTextLink" @click="alternarTextoResposta()">Se preferir, pode acrescentar uma resposta textual...</div>
                </div>
            </div>
            <!-- Resposta textual -->
            <div id="TextoResposta" class="row">
                <div class="box" v-show="perguntaAtual">
                    <div style="width:60%;text-align:center;margin:5px;" class="texto-resposta">
                        <!-- <input style="width: 100%;" type="text"> -->
                        <textarea  v-if="perguntaAtual"
                            id="textAreaResposta" cols="50" rows="3" 
                            v-model="perguntaAtual.textorespostaformavaliacao">
                        </textarea>
                    </div>
                </div>
            </div>
            <div id="BotaoResposta" class="row">
                <div class="box" v-show="perguntaAtual">
                    <div class="btn" style="width:20%;" @click="enviarRespostaTextual()">Enviar</div>
                </div>
                <div 
                    style="opacity:0;background-color:red;width:100px;height:50px;border-radius:5px;cursor:pointer;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-family:helvetica">
                </div>
            </div>
        </div>
    </div>
    <!-- Reviews -->
    <div class="fundo center" v-show="reviews">
        <div class="container">
            <!-- Ícone Reviews -->
            <div class="row" v-if="perguntaAtual">
                <div class="box">
                    <div>
                        <span class="material-icons icon" style="color: lightgreen;">reviews</span>
                    </div>
                </div>
                <div><h3 style="text-align:center;">Gostaria comentar nos site reviews?</h3></div>
            </div>
            <!-- Links dos Portais -->
            <div class="row">
                <div v-for="portal in portaisReviews" :key="portal.idhotelportal">
                    <!-- <div class="text-center" @click="openSite(portal.link)">
                        <h5 class="mb-0 text-white">{{portal.nome}}</h5>
                    </div> -->
                    <reviews-portal-card :portal="portal"></reviews-portal-card>
                </div>
            </div>
            <div class="row" v-if="$store.getters.GetLoginInfo.app_ativado">
                <div @click="concluir()" class="text-center btn ronded primary text-white">
                    <span style="text-align:center;">Voltar ao aplicativo FDGuest</span>
                </div>
            </div>
        </div>
    </div>    
    <!-- Concluído -->
    <div class="fundo center" v-show="concluido">
        <div class="container">
            <!-- Ícone da Categoria de pergunta -->
            <div class="row" v-if="perguntaAtual">
                <div class="box">
                    <div>
                        <span class="material-icons icon" style="color: lightgreen;">done_all</span>
                    </div>
                </div>
            </div>
            <!-- Texto da conclusão -->
            <div class="row">
                <div><h3 style="text-align:center;">Grato por responder!</h3></div>
                <div><h3 style="text-align:center;"></h3></div>
                <div><h3 style="text-align:center;">Suas respostas foram registradas!</h3></div>
            </div>
        </div>
    </div>
    <!-- footer-->
    <div v-if="!reviews" class="footer no-bg-shadow py-1">
        <div class="row justify-content-center">
            <div class="ul">
                <div @click="primeira()" :class="{'li': indiceAtual > 0, 'li li-disabled': indiceAtual == 0}">
                    <span>Primeira</span>
                    <!-- <span class="material-icons icon">first_page</span> -->
                    <!-- <div class="btn-primeira"></div> -->
                </div>
                <div  @click="anterior()" :class="{'li': indiceAtual > 0, 'li li-disabled': indiceAtual == 0}">
                    <span>Anterior</span>
                    <!-- <span class="material-icons icon">navigate_before</span> -->
                    <!-- <div class="btn-anterior"></div> -->
                </div>
                <div @click="proximo()" :class="{'li': indiceAtual < (perguntas.length-1), 'li li-disabled': indiceAtual == (perguntas.length-1)}">
                    <span>Próximo</span>
                    <!-- <span class="material-icons icon">navigate_next</span> -->
                    <!-- <div class="btn-proximo"></div> -->
                </div>
                <div @click="ultima()" :class="{'li': indiceAtual < (perguntas.length-1), 'li li-disabled': indiceAtual == (perguntas.length-1)}">
                    <span>Última</span>
                    <!-- <span class="material-icons icon">last_page</span> -->
                    <!-- <div class="btn-ultima"></div> -->
                </div>
                <div @click="previewConcluir()" class="li">
                    <span>Concluir</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import ReviewsPortalCard from './ReviewsPortalCard.vue';

export default {
    components: {
        ReviewsPortalCard
    },
    data() {
        return {
            componentName: 'Perguntas',
            idavaliacao: (this.$route.params.idavaliacao ? this.$route.params.idavaliacao : 0),
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            perguntas: [],
            perguntaAtual: {},
            indiceAtual: 0,
            concluido: false,
            respostasPossiveis: [
                {
                    id: 1,
                    nome: 'ruim',
                    texto: this.$t('resposta.ruim'),
                    nota: 1
                },
                {
                    id: 2,
                    nome: 'regular',
                    texto: this.$t('resposta.regular'),
                    nota: 2
                },
                {
                    id: 3,
                    nome: 'bom',
                    texto: this.$t('resposta.bom'),
                    nota: 3
                },
                {
                    id: 4,
                    nome: 'otimo',
                    texto: this.$t('resposta.otimo'),
                    nota: 4
                },
                {
                    id: 5,
                    nome: 'excelente',
                    texto: this.$t('resposta.excelente'),
                    nota: 5
                }
            ],
            portaisReviews: [],
            reviews: false
        }
    },
    computed: {
        media () {
            var totalNotas = 0.0
            var notasCount = 0
            for (var i = 0; i < this.perguntas.length; i++) {
                var perg = this.perguntas[i];
                if (perg.nota) {
                    notasCount += 1
                    totalNotas += parseFloat(perg.nota)
                }
            }
            var result = notasCount > 0 ? (totalNotas / notasCount).toFixed(2) : 0
            return result
        }
    },
    methods: {
		openSite(url) {
			if (url) {
				url = new String(url)
				if (url.substring(0,4) != 'http') {
					url = 'http://' + url
				}
				window.open(url,'')
			}
		},
        getSelectedClass(nota){
            var result = ''
            if(this.perguntaAtual) { 
                if (this.perguntaAtual.nota == null){
                    result = 'ponteiro'
                } else {
                    result = this.perguntaAtual.nota == nota ? 'ponteiro selected': 'ponteiro'
                }
            }
            return result;
        },
        getPerguntaClass(nota) {
            var result = ''
            if(this.perguntaAtual) { 
                if (this.perguntaAtual.nota == null){
                    switch(nota) {
                        case 1: result = 'ruim'; break;
                        case 2: result = 'regular'; break;
                        case 3: result = 'bom'; break;
                        case 4: result = 'otimo'; break;
                        case 5: result = 'excelente'; break;
                    }
                } else {
                    switch(nota) {
                        case 1: result = this.perguntaAtual.nota == 1 ? 'ruim-selected' : 'ruim'; break;
                        case 2: result = this.perguntaAtual.nota == 2 ? 'regular-selected' : 'regular'; break;
                        case 3: result = this.perguntaAtual.nota == 3 ? 'bom-selected' : 'bom'; break;
                        case 4: result = this.perguntaAtual.nota == 4 ? 'otimo-selected' : 'otimo'; break;
                        case 5: result = this.perguntaAtual.nota == 5 ? 'excelente-selected': 'excelente'; break;
                    }
                }
            }
            return result;
        },
        fade(element) {
            var op = 1;  // initial opacity
            var timer = setInterval(function () {
                if (op <= 0.1){
                    clearInterval(timer);
                    element.style.display = 'none';
                }
                element.style.opacity = op;
                element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                op -= op * 0.085;
            }, 40);
        },
        unfade(element) {
            var op = 0.1;  // initial opacity
            element.style.display = 'block';
            var timer = setInterval(function () {
                if (op >= 1){
                    clearInterval(timer);
                    element.focus();
                }
                element.style.opacity = op;
                element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                op += op * 0.085;
            }, 40);
        },
        alternarTextoResposta(){
            var obj = document.getElementById('TextoResposta')
            var obj2 = document.getElementById('BotaoResposta')
            var link = null
            if (obj && obj2) {
                if(obj.style.display == 'none') {
                    this.unfade(obj)
                    this.unfade(obj2)
                    link = document.getElementById('toggleTextLink')
                    if (link) {
                        link.innerText = '...ocultar caixa de texto'
                    }
                    // coloca o foco na área de texto
                    var textArea = document.getElementById('textAreaResposta')
                    if (textArea) {
                        textArea.focus()
                    }
                } else {
                    this.fade(obj)
                    this.fade(obj2)
                    link = document.getElementById('toggleTextLink')
                    if (link) {
                        link.innerText = 'Se preferir, pode acrescentar uma resposta textual...'
                    }
                }
            }
        },
        primeira() {
            if (!this.concluido) {
                this.indiceAtual = 0;
                this.perguntaAtual = this.perguntas[this.indiceAtual];
            }
        },
        async proximo() {
            if (!this.concluido) {
                if (this.indiceAtual < (this.perguntas.length-1)) {
                    this.indiceAtual += 1;
                    if (this.perguntaAtual.textorespostaformavaliacao) {
                        await this.enviarRespostaTextual();
                    }
                    this.perguntaAtual = this.perguntas[this.indiceAtual];
                }
            }
        },
        anterior() {
            if (!this.concluido) {
                if (this.indiceAtual > 0){
                    this.indiceAtual -= 1;
                    this.perguntaAtual = this.perguntas[this.indiceAtual];
                }
            }
        },
        ultima() {
            if (!this.concluido) {
                this.indiceAtual = this.perguntas.length-1;
                this.perguntaAtual = this.perguntas[this.indiceAtual];
            }
        },
        previewConcluir() {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.hotel.idhotel,
                idioma: this.$i18n.locale,
                pontolimite: this.media
            }
            console.error(JSON.stringify(params))
			axios.post(CONSTANTS.API_URL + '/fdguest/hotelportal/select', params, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
                        this.portaisReviews = response.data
                        if (this.portaisReviews.length > 0) {
                            this.reviews = true;
                        } else {
                            this.concluir();
                        }
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});            
        },
        concluir() {
            this.reviews = false;
            this.concluido = true;
            // Pra não entrar na avaliação novamente...
            this.$store.dispatch('set_Avaliacao', 0);
            // Aguardar um tempo antes de redirecionar para o aplicativo
            if (this.$store.getters.GetLoginInfo.app_ativado) {
                var op = 3;
                var aRouter = this.$router
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        aRouter.push('/home');
                    }
                    op -= op * 0.085;
                }, 40);
            }
        },
        carregarPerguntas() {
            var params = {
                    idtipoavaliacao :  this.idavaliacao,
                    idioma: this.$i18n.locale,
                    idreservasfront: this.$store.getters.GetLoginInfo.idreservasfront,
                    idhospedepms: this.$store.getters.GetLoginInfo.idhospedepms,
                    ativo: true
				}
            console.log('carregarPerguntas')
            console.log(JSON.stringify(params))
			axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/select', params, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						this.$store.dispatch('set_Perguntas', response.data);
                        this.perguntas = response.data
                        if (this.perguntas.length>0) {
                            this.perguntaAtual = this.perguntas[0]
                        }
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
        },
        selecionarResposta(r) {
            var obj = document.getElementById('pulse')
            if (obj) {
                if (r.data.length == 0) {
                    if (obj.classList.contains('pulse-css')) {
                        obj.classList.remove('pulse-css')
                    }
                } else {
                    if (!obj.classList.contains('pulse-css')) {
                        obj.classList.add('pulse-css')
                    }
                }
            }
        },
        async enviarRespostaTextual() {
            const respostaTextual = this.perguntaAtual.textorespostaformavaliacao.trim();
            if (!respostaTextual) {
                return;
            }
            await this.inserirResposta(0);
            var obj = document.getElementById('TextoResposta')
            var obj2 = document.getElementById('BotaoResposta')
            var link = null
            if (obj && obj2) {
                this.fade(obj)
                this.fade(obj2)
                link = document.getElementById('toggleTextLink')
                if (link) {
                    link.innerText = 'Resposta enviada. Clique para abrir novamente.'
                }
            }            
        },
        async inserirResposta(nota) {
            var notaAUsar = nota
            if (notaAUsar == 0) {
                // veio pelo botão enviar
                notaAUsar = this.perguntaAtual.nota
            }
            var textLinkElement = document.getElementById('toggleTextLink')
            if (textLinkElement) {
                textLinkElement.style.display = 'block'
            }
            if (this.perguntaAtual) {
                if (!this.perguntaAtual.idrespostaformavaliacao) {
                    // Se não há resposta, inserir...
                    var params = {
                            idusuario: 1,
                            idperguntaformavaliacao :  this.perguntaAtual.idperguntaformavaliacao,
                            idreservasfront: this.$store.getters.GetLoginInfo.idreservasfront,
                            idhospedepms: this.$store.getters.GetLoginInfo.idhospedepms,
                            nota: notaAUsar,
                            textorespostaformavaliacao: (this.perguntaAtual.textorespostaformavaliacao ?
                            this.perguntaAtual.textorespostaformavaliacao.trim() : '')
                        }
                    //console.log(JSON.stringify(params))
                    axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/insert', params, {headers: this.headers})
                    .then(response => {
                        if (response.status == 200) {
                            if (response.data) {
                                this.perguntaAtual.nota = nota;
                                this.perguntaAtual.idrespostaformavaliacao = response.data.ids[0];
                            }
                        } else {
                            console.log(response.message)
                        }
                    })
                    .catch((err) => {
                        console.log({ err_menu: err });
                    });
                } else {
                    // Atualizar a resposta existente
                    var paramsUpdate = {
                        idrespostaformavaliacao :  this.perguntaAtual.idrespostaformavaliacao,
                        nota: notaAUsar,
                        textorespostaformavaliacao: (this.perguntaAtual.textorespostaformavaliacao ?
                        this.perguntaAtual.textorespostaformavaliacao.trim() : '')
                    }
                    axios.put(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/update', paramsUpdate, {headers: this.headers})
                    .then(response => {
                        if (response.status == 200) {
                            if (response.data) {
                                this.perguntaAtual.nota = notaAUsar
                                this.perguntaAtual.nota = notaAUsar
                            }
                        } else {
                            console.log(response.message)
                        }
                    })
                    .catch((err) => {
                        console.log({ err_menu: err });
                    });
                }
            }
        }
    },
    created() {
        this.idavaliacao = this.$store.getters.GetAvaliacao ? this.$store.getters.GetAvaliacao : 0
        this.carregarPerguntas()
    },
    mounted() {
        this.alternarTextoResposta()
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box;
}
.main {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgb(45,73,203) , rgb(8,206,246));
}
.fundo {
    background-color: rgb(8,206,246);
    /*background-image: linear-gradient(to right, rgb(45,73,203) , rgb(8,206,246));*/
    background: url(../../img/bg-login.png) no-repeat center ;
    background-size: cover;
    color: white;
    border-radius: 20px;
    height: 70%;
    width: 85%;
    position: relative;
    border: 3px solid navy;
}
.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
}
.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.box {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.box div {
    background-color: transparent;
}
.box .btn {
    color: white;
    background-color: #2c49cb;
    max-height: 3rem;
    text-align: center!important;
}
/* .btn:hover {
    cursor: pointer;
} */
.selected {
    cursor: pointer;
    background-color: black;
    border: 1px white;
    opacity: 0.9;
}
/* .ruim {
    background: url(../../img/ruim.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.ruim-selected {
    background: url(../../img/ruim-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.regular {
    background: url(../../img/regular.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.regular-selected {
    background: url(../../img/regular-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.bom {
    background: url(../../img/bom.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.bom-selected {
    background: url(../../img/bom-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.otimo {
    background: url(../../img/otimo.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.otimo-selected {
    background: url(../../img/otimo-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.excelente {
    background: url(../../img/excelente.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.excelente-selected {
    background: url(../../img/excelente-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
} */
.texto-resposta
{
    min-height: 10%;
    text-decoration: none;
    border: none;
    background-color: black;
}
.texto-resposta:focus
{
    min-height: 10%;
    text-decoration: none;
    /* border: 1px rgb(153, 225, 241); */
    border-radius: 20px;
    text-decoration: none;
    color: rgb(53, 53, 53);
    background-color: rgb(53, 186, 212);
}
.texto-resposta textarea
{
    padding: 10px;
    min-height: 10%;
    /* border: 1px rgb(153, 225, 241); */
    border-radius: 20px;
    text-decoration: none;
    color: rgb(53, 53, 53);
    background-color: rgb(226, 248, 252);
}
.ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    margin-top: 10vh;
    padding: 0;
    overflow: hidden;
    background-color: #2c49cb;
    text-align: center;
    border-radius: 3vw;
}
.li {
   padding: 0.8rem;
   float: right;
   color: white;
   cursor: pointer;
}
.li-disabled {
    float: right;
    color: grey;
    opacity: 0.5;
}
.li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    font-size: 1rem;
    cursor: pointer;
}
/* Change the link color to #111 (black) on hover */
.li a:hover {
    color: rgb(9, 0, 58);
    background-color: rgb(228, 250, 255);
    border-radius: 3vw;
}
.li:hover {
    background-color: rgb(13, 177, 214);
    border-radius: 3vw;
}
.material-icons {
    font-size: 48px;        
}
.none {
    display: none;
}
.block {
    display: block;
}
.textLink:hover {
    color: rgb(214, 211, 211);
    /*font-weight: bold;*/
}
.btn-proximo {
    background: url(../../img/proximo.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.btn-anterior {
    background: url(../../img/anterior.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.btn-primeira {
    background: url(../../img/primeira.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
.btn-ultima {
    background: url(../../img/ultima.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 50px;
    min-height: 50px;
    z-index: 1000;
}
</style>