<template>
    <div class="portal-link ponteiro mx-5 py-3" @click="abrirLink(portal.link)">
      <div class="row">
        <div class="col-6 text-right">
          <img style="height:30px;" v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
            :id="'imgPortal_' + portal.idhotelportal"
          />
        </div>
        <div class="col-6 text-left">
          <h4>{{portal.nome}}</h4>
        </div>
      </div>
    </div>
</template>

<script>
import CONSTANTS from "../constants";
import axios from "axios";

export default {
  props: {
    portal: {
        type: Object,
        required: true,
    }
  },    
  data() {
    return {
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      imagemSrc: null,
      imagemTipo: null
    };
  },
  methods: {
		carregarImagem() {
      if (this.portal.imagem) {
        this.imagemSrc = null
        this.imagemTipo = null
        axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
            { id: this.portal.imagem }, { headers: this.headers })
        .then((response) => {
            if (response.status == 200) {
                var imgObj = response.data[0]
                if (imgObj) {
                    this.imagemSrc = Buffer.from(imgObj.dados).toString()
                    this.imagemTipo = imgObj.tipo
                }
            }
        })
        .catch((e) => {
            console.error(e);
        });
      }
		},    
    abrirLink(link) {
			if (link) {
				var url = new String(link)
				if (url.substring(0,4) != 'http') {
					url = 'http://' + url
				}
				window.open(url,'')
			}
    }
  },
  mounted() {
    this.carregarImagem()
  },
};
</script>

<style scoped>
.portal-link {
  background-color: transparent;
  opacity: 1;
  border-radius: 0.5rem;
  border: 2px blue!important;
  /* box-shadow: 5px 5px 5px blue; */
  z-index: -1000;
}
.es-shadow {
    border: 2px indigo!important;
    box-shadow: 3px 3px 3px indigo;
}

</style>