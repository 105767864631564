<template>
<div>
    <Loader />
    <Menu />
    <div class="backdrop"></div>
    <main class="flex-shrink-0 main has-footer">
        <!-- navbar -->
        <Header @atualizarNotificacoes="[loadNotificacoes()]"/>
		<div class="main-container mb-1 mt-1" v-if="$store.getters.GetLoginInfo.totalsols > 0">
            <div class="border-0 mb-0 p-0">
                <div class="card-body p-0 pl-3 pr-3 ponteiro">
                    <div class="row align-items-center" @click="$router.push('/solicitacoes')">
                        <div class="col-auto pr-0">
                            <div class="avatar avatar-50 border-0 bg-danger-light rounded-circle text-danger">
                                <i class="material-icons vm text-template">receipt</i>
                            </div>
                        </div>
                        <a>
                            <div class="col-auto align-self-center">
                                <h6 class="mb-1">{{$t('minhassolicitacoes')}}</h6>
                                <p class="small text-secondary">{{$t('todosositenssolicitados')}}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-container my-2">
            <!-- Atalhos do Menu Principal -->
            <div class="container mb-4" v-if="$store.getters.GetLoginInfo.app_ativado">
                <div class="card">
                     <div class="card-body text-center ">
                        <div class="row justify-content-equal no-gutters" >
                            <div class="col-4 col-md-2 mb-3" v-for="(item, idx) in primeirosItens" :key="idx">
                                <a  @click="loadSetor(item)" class="ponteiro icon icon-50 rounded-circle mb-1 bg-default-light text-default"><span class="material-icons font-size-36">{{item.icone}}</span></a>
                                <p class="text-secondary"><small>{{item.descricao}}</small></p>
                            </div>
                         
                        </div>
                        <button class="btn btn-sm btn-outline-secondary rounded" id="more-expand-btn"
                            v-if="$store.getters.GetDemaisItens.length>0"
                        >{{$t('maisitens')}} <span class="ml-2 small material-icons">expand_more</span></button>
                        <div class="row justify-content-equal no-gutters" id="more-expand">
                            <div class="col-4 col-md-2" v-for="(item, idx) in $store.getters.GetDemaisItens" :key="idx">
								<a  @click="loadSetor(item)" class="ponteiro icon icon-50 rounded-circle mb-1 bg-default-light text-default"><span class="material-icons">{{item.icone}}</span></a>
                                <p class="text-secondary"><small>{{item.descricao}}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			
			<div class="container mb-4 text-center">
                <div class="card bg-default-secondary shadow-default">
                    <div class="card-body">
                        <!-- swiper -->
                        <div class="swiper-container addsendcarousel text-center">
							<h6 class="text-left mb-2">{{$t('periododaacomodacao')}}: 
                                {{formatarData(this.$store.getters.GetLoginInfo.datacheckin)}} - 
                                {{formatarData(this.$store.getters.GetLoginInfo.datacheckoutprev)}}
                            </h6>
							<div class="progress h-5  mb-3">
                                <div id="progressBar" class="progress-bar bg-default" role="progressbar" style="width:35%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <!-- paginacao -->
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- fim swiper -->
            
			<!-- Período e Últimas transações -->
            <div class="container mb-4">
                <template v-if="$store.getters.GetUltimasTransacoes.length>0">
                    <div class="row">
                        <div class="col">
                            <h6 class="subtitle mb-3">{{$t('ultimastransacoes')}}</h6>
                        </div>
                        <div class="col-auto"><a @click="$router.push('/transacoes')" class="text-default ponteiro">{{$t('vertodas')}}</a></div>
                    </div>
                    <div class="row">
                            <div class="col-12 col-md-6" v-for="(tran, i) in $store.getters.GetUltimasTransacoes" v-bind:key="i">
                                <div class="card border-0 mt-2">
                                    <div class="card-body ">
                                        <div class="row align-items-center">
                                            <div class="col-auto pr-0">
                                                <div class="avatar avatar-50 border-0 bg-default-light rounded-circle text-default">
                                                    <i class="material-icons vm text-template">local_atm</i>
                                                </div>
                                            </div>
                                            <div class="col-4 align-self-center">
                                                <h6 class="mb-1">{{tran.descricao}}</h6>
                                                <p class="small text-secondary">{{formatarData(tran.data)}}</p>
                                            </div>
                                            <div class="col-auto align-self-center border-left">
                                                <h6 class="mb-1">{{toCurrency(tran.valor)}}</h6>
                                                <p class="small text-secondary">{{$t('quarto')}}: {{tran.uh}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </template>
            </div>
            
            <!-- Atalhos Governança -->
            <div class="container" v-if="$store.getters.GetLoginInfo.totalgovernanca > 0 && $store.getters.GetLoginInfo.app_ativado">
                <div class="container-center">
                    <div class="card" style="max-width:350px;">
                        <div class="card-body">
                            <h6 class="mb-1">{{$t('serviconoquarto')}}</h6>
                            <p class="text-secondary small">{{$t('ativeoitemdeacordocomoservicodesejado')}}</p>
                            <div class="row">
                                <div class="col-6 col-md-auto">
                                    <div class="custom-control custom-switch">
                                        <input type="radio" name="menustyle" class="custom-control-input" id="menu-overlay" :checked="naopertube" @click="changeStatusN(naopertube)">
                                        <label class="custom-control-label" for="menu-overlay">{{$t('naopertube')}}</label>
                                    </div>
                                </div>
                                <div class="col-6 col-md-auto">
                                    <div class="custom-control custom-switch">
                                        <input type="radio" name="menustyle" class="custom-control-input" id="menu-pushcontent" :checked="limparquarto" @click="changeStatusL(limparquarto)">
                                        <label class="custom-control-label" for="menu-pushcontent">{{$t('limparquarto')}}</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-auto mt-3">
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            &nbsp; {{$t('de')}}:<input type="time" class="form-control" autofocus="" v-model="horariode">
                                        </div>
                                        <div class="col-6">
                                            &nbsp; {{$t('ateas')}}<input type="time" class="form-control" autofocus="" v-model="horarioate">
                                        </div>
                                    </div>
                                    <div class="row mb-4" v-if="limparquarto">
                                        <div class="col-12">
                                            <label class="form-control-label">{{$t('informacaoadicional')}}:</label>
                                            <textarea v-model="infoServicoQuarto" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
                                        </div>
                                    </div>
                                    <template v-if="!validTimes || !validNaoPerturbe">
                                        <div class="horario-total">
                                            <div class="fora-horario">
                                                Fora do horário permitido. Horário inicial deve ser menor que o final.
                                            </div>
                                            <div class="horario-permitido" v-if="!validTimes">
                                                Escolha entre {{this.$store.getters.GetLoginInfo.hotel.limpezahorarioinicial}} e {{this.$store.getters.GetLoginInfo.hotel.limpezahorariofinal}}
                                            </div>
                                        </div>
                                    </template>                                
                                    <button :disabled="!validTimes||!validNaoPerturbe" @click="solServicoQuarto()" class="btn btn-block btn-default rounded mr-3  mt-3">{{$t('confirmarsolicitacao')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Conveniências no Hotel -->
            <div class="container mt-1" v-if="listaConveniencias.length > 0 && $store.getters.GetLoginInfo.totalgovernanca > 0 && $store.getters.GetLoginInfo.app_ativado">
                <div class="row">
                    <div class="col text-center">
                        <h5 class="subtitle">{{$t('conveniencianohotel')}}</h5>
                        <p class="text-secondary">{{$t('espacoseservicosdisponivelnohotel')}}</p>
                    </div>
                </div>
                <!-- <div class="row text-center my-3 align-content-center;"> -->
                <div class="container-cards text-center">
                    <div class="card-conveniencia border" v-for="(conv, index) in listaConveniencias" :key="index">
                        <div class="row">
                            <div class="col-12">
                                <div class="avatar avatar-60 bg-default-light rounded-circle text-default">
                                    <i class="material-icons vm md-36 text-template">{{conv.icone}}</i>
                                </div>
                            </div>
                            <div class="col-12">
                                <!-- <div class="p-3"><strong>{{ conv.titulo }}</strong></div> -->
                                <h6 class="mt-3 mb-0 font-weight-normal">{{ conv.titulo }}</h6>
                            </div>
                            <div class="col-12">
                                <div class="text-secondary small">{{conv.subtitulo}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Informações de debug -->
            <template v-if="(env === 'development') && this.memory.totalJSHeapSize && this.memory.jsHeapSizeLimit && this.memory.usedJSHeapSize">
                <div style="display: block;text-align:center;">
                    <p>Memory <b>Heap Size</b> (total): <b>{{toCurrency(this.memory.totalJSHeapSize)}}</b></p>
                    <p>Memory <b>Heap</b> Size <b>Limit</b>: <b>{{toCurrency(this.memory.jsHeapSizeLimit)}}</b></p>
                    <p>Memory <b>Used Heap</b> Size: <b>{{toCurrency(this.memory.usedJSHeapSize)}}</b></p>
                </div>
            </template>            
        </div>
    </main>
    <Footer :itemdestacar="1"/>
</div>
</template>

<script>
    import CONSTANTS from '../constants'
    import axios from 'axios'
    import Loader from '../components/Loader.vue'
    import Menu from '../components/Menu.vue'
    import Header from '../components/Header.vue'
    import Footer from '../components/Footer.vue'
    import moment from "moment"
    export default {
        components: {
            Menu, Header, Footer, Loader
        },
        data() {
            return {
                itens: [],
                itensprimeiro: [],
                novoItens: [],
                headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
                dias: 0,
                naopertube: true,
                limparquarto: false,
                servicoquarto: '',
                infoServicoQuarto: '',
                horariode: this.$store.getters.GetLoginInfo.hotel.limpezahorarioinicial,
                horarioate: this.$store.getters.GetLoginInfo.hotel.limpezahorariofinal,
                memory: null,
                env: '', 
                //intervalId: 0
                salaoSPA: true,
                listaConveniencias: []
            }
        },
        computed: {
            primeirosItens() {
                return this.$store.getters.GetPrimeirosItens.filter((x)=>x.link != '/salao' || this.salaoSPA)
            },
            validNaoPerturbe() {
                var horarioInicial = this.horariode?this.horariode:'11:00';
                var horarioFinal = this.horarioate?this.horarioate:'16:00';
                var aStr = horarioInicial.replace(/[:]/g, '')
                var a = parseInt(aStr)
                var bStr = horarioFinal.replace(/[:]/g, '')
                var b = parseInt(bStr)
                if (this.naopertube) {
                    return (a<=b);
                } else {
                    return true;
                }
            },
            validTimes() {
                var horarioInicial = this.horariode?this.horariode:'11:00';
                var horarioFinal = this.horarioate?this.horarioate:'16:00';
                var limiteInferior = parseInt(this.$store.getters.GetLoginInfo.hotel.limpezahorarioinicial.replace(/[:]/g, ''));
                var limiteSuperior = parseInt(this.$store.getters.GetLoginInfo.hotel.limpezahorariofinal.replace(/[:]/g, ''));
                var aStr = horarioInicial.replace(/[:]/g, '')
                var a = parseInt(aStr)
                var bStr = horarioFinal.replace(/[:]/g, '')
                var b = parseInt(bStr)
                if (this.limparquarto) {
                    return (a <= b && a >= limiteInferior && b <= limiteSuperior);
                } else {
                    return true;
                }
            }
        },
        methods: {       
            checkSalaoSpa() {
                let params = {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idioma: this.$i18n.locale
                }
                console.log(params)
                axios.post(`${CONSTANTS.API_URL}/fdguest/servico/select`,params, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        console.log(`status 200: ${response.data.length}`)
                        this.salaoSPA = (response.data.length > 0);
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            },
            buscarConveniencias() {
                let params = {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idioma: this.$i18n.locale,
                    ativo: true
                }
                console.log(params)
                axios.post(`${CONSTANTS.API_URL}/fdguest/conveniencia/select`,params, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        console.log(`status 200: ${response.data.length}`)
                        this.listaConveniencias = response.data;
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            },            
            solServicoQuarto() {
                var str = this.$store.getters.GetLoginInfo.nome;
                var res = str.split(" ");
                var nome = res[0];
                var sobrenome = (res.length==1)?'':res[res.length-1];
                if (this.naopertube) {
                    this.servicoquarto = `${this.$t('favornaopertubar')}` + this.horariode + ` ${this.$t('e')} ` + this.horarioate
                } else {
                    this.servicoquarto = `${this.$t('favorlimpar')}` + this.horariode + ` ${this.$t('e')} ` + this.horarioate
                }
                if(this.infoServicoQuarto.trim()!='') {
                    this.servicoquarto += `\n${this.$t('informacaoadicional')}: ${this.infoServicoQuarto}`
                }
                var servicodequarto = {
                    "nome" : nome,
                    "sobrenome" : sobrenome,
                    "coduh" : this.$store.getters.GetLoginInfo.coduh,
                    "idhotlinesetores" : 5,
                    "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                    "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
                    "idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                    "idusuario" : "1",
                    "textohotlinesolicitacoes" : this.servicoquarto,
                    "idtiposolicitacao" : CONSTANTS.TIPO.ARRUMAR
                };
                //console.error(JSON.stringify(servicodequarto));
                axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
                servicodequarto, {headers: this.headers}).then(response => {
                    if(response.status == 200) {
                        this.$router.push("/sucesso")
                    }   else {
                         this.$router.push("/error")
                    }
                })
            },
            changeStatusL() {
                this.limparquarto = true;
                this.naopertube = false;
                this.servicoquarto = `${this.$t('favorlimpar')}` + this.horariode + 'e' + this.horarioate
            },
            changeStatusN() {
                this.limparquarto = false;
                this.naopertube = true;
                this.servicoquarto = `${this.$t('favornaopertubar')}` + this.horariode + 'e' + this.horarioate
            }, 
            formatarData(aDate) {
                //return moment(aDate).format('DD-MM-YY HH:MM');
                return moment(aDate).locale(this.$i18n.locale).format('L');
            },
            toCurrency(x) {
                return x.toFixed(2).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            loadSetor(item) {
                    this.$store.dispatch('set_Current_Item', item);
                    this.$router.push(item.link)
            },
            carregarEstadia() {
                this.dias = this.$store.getters.GetLoginInfo.dias;
                var diasCorridos = this.dias - this.$store.getters.GetLoginInfo.faltam;
                if (diasCorridos == 0) {diasCorridos = 1}
                var progressValue = Math.round((diasCorridos / this.$store.getters.GetLoginInfo.dias) * 100).toString();
                var pb = document.getElementById('progressBar');
                pb.style.width =  progressValue.toString()+'%';
            },
            habilitarExpansao() {
                var b = document.getElementById('more-expand-btn');
                if (b) {
                    b.onclick = function () {
                        var m = document.getElementById('more-expand');
                        if (m) {
                            m.classList.add('active');
                            b.classList.add('active');
                        }
                    };
                }
            },
            carregarNotificacoes() {
                var params = {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    para: this.$store.getters.GetLoginInfo.idpessoa,
                    tipo: 'H',
                    idioma: this.$store.getters.GetLoginInfo.idioma.codidioma,
                    whereRaw: 'n.datavisualizacao is null'
                };
                axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', params, {headers: this.headers})
                .then(response => {
                    if (response.status == 200) {
                        if (response.data) {
                            this.$store.dispatch('set_Notificacoes', response.data);
                        }
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });                
            },
            loadNotificacoes() {
                axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', {
                        idhotel: this.$store.getters.GetLoginInfo.idhotel,
                        para: this.$store.getters.GetLoginInfo.idpessoa,
                        tipo: 'H',
                        whereRaw: 'n.datavisualizacao is null',
                        idioma: this.$i18n.locale
                    }, {headers: this.headers})
                .then(response => {
                    if (response.status == 200) {
                        if (response.data) {
                            this.$store.dispatch('set_Notificacoes', response.data);
                            var obj = document.getElementById('pulse')
                            if (obj) {
                                if (response.data.length == 0) {
                                    if (obj.classList.contains('pulse-css')) {
                                        obj.classList.remove('pulse-css')
                                    }
                                } else {
                                    if (!obj.classList.contains('pulse-css')) {
                                        obj.classList.add('pulse-css')
                                    }
                                }
                            }
                        }
                    } else {
                        console.log(response.message)
                    }
                })
                .catch((err) => {
                    if (err.message.includes('403')) {
                        console.log('Erro de autenticação! Direcionar para login...');
                        sessionStorage.clear();
                        //clearInterval(this.intervalId);
                        this.$router.push('/');
                    } else {
                        console.log(JSON.stringify(err))
                    }
                });
            },
/*             connectScocket: function() {
                this.$socket.disconnect();
                
                this.$socket.io.opts.query.email = this.$store.getters.GetLoginInfo.email
                
                this.$socket.connect();
                this.sockets.subscribe("ATUALIZARSOLS", (dados) => {
                    this.loadNotificacoes()
                    this.carregarNotificacoes()
                    console.log(dados.msg)

                });
            }   */
        },
        mounted(){
            
            if(this.$store.getters.GetLoginInfo.permitir_chamado === true){
                this.checkSalaoSpa();
                this.habilitarExpansao();
                this.carregarEstadia();
                this.carregarNotificacoes();
                window.scrollTo(0,0); // Vai pro topo da página
                this.memory = window.performance.memory;
                //this.connectScocket();
                this.buscarConveniencias();
            }else{
                this.$router.push('/avaliacoes')
            }
            
        },
        created() {
            this.memory = window.performance.memory;
            this.env = process.env.NODE_ENV;
            // this.intervalId = window.setInterval(() => {
            //     this.loadNotificacoes()
            // }, 15000);
        }
    }
</script>

<style scoped>
    ul {
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 0px;
        padding-inline-end: 0px;
    }
    li {
        list-style-type:none;
    }
    .container-cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        background-color: transparent;
    }
    .card-conveniencia {
        flex: 1 1 100px;
        background-color: #fff;
        margin: 0.3rem;
        border-radius: 0.8rem;
        padding: 0.5rem;
    }

    .border {
        border: 1px solid gray;
    }

    .transparent {
        background-color: transparent;
    }

    .container-center {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        width: 100%;
    }
</style>
