<template>
    <div>
        <!-- Logo carregando -->
        <Loader />
        <Menu />
        <!-- Begin page content -->
        <main class="flex-shrink-0 main has-footer">
            <!-- Fixed navbar -->
            <Header />
            <!-- page content start -->
            <!-- <div class="container-fluid px-0">
                <div class="card overflow-hidden">
                    <div class="card-body p-0 h-150">
                        <div class="background">
                            <img src="../../img/esmeraldahotel.png" alt="" >
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div>
                <input @change="uploadImg"  type="file" id="file" name="file" accept="image/png,image/jpg,image/jpeg" class="">
            </div> -->
            <div class="container-fluid text-center mb-4">
                
                <div class="avatar avatar-140 rounded-circle mx-auto shadow">
                    <div class="background">
                        <label for="file"><img id="img" :src="avatar" alt=""></label>
                        <input @change="uploadImg"  type="file" id="file" name="file" accept="image/png,image/jpg,image/jpeg" class="">
                    </div>
                </div>
            </div>

            <div v-if="this.$store.getters.GetLoginInfo" class="container mb-4 text-center text-white">
                <h5 class="mb-4">{{this.$store.getters.GetLoginInfo.nome}}</h5>
            
                <h6 class="mb-1">{{this.$store.getters.GetLoginInfo.nomehotel}}</h6>
                <p>
                    {{$t('quartoacomodado')}}: <strong>{{this.$store.getters.GetLoginInfo.coduh}}</strong>
                    {{$t('reserva')}}: <strong>{{this.$store.getters.GetLoginInfo.idreservasfront}}</strong>
                </p>
            </div>

            <div class="main-container">
                <div class="container mb-4">
                    <div class="row mb-2" v-if="$store.getters.GetLoginInfo.totalsols > 0">
                        <div class="col-12">
                            <button @click="$router.push('solicitacoes')" class="btn btn-outline-default px-2 btn-block rounded"><span class="material-icons mr-1">receipt_long</span> {{$t('solicitacoes')}}</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 mt-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="mb-1">{{$t('periododaacomodacao')}}</h6>
                                            <p class="text-secondary">
                                                {{formatarData(this.$store.getters.GetLoginInfo.datacheckin)}} - 
                                                {{formatarData(this.$store.getters.GetLoginInfo.datacheckoutprev)}}
                                                <span class="text-success">{{$t('total')}}: {{dias}} {{$t('diarias')}}</span>
                                            </p>

                                        </div>
                                    </div>
                                    <div class="progress h-5 mt-3">
                                        <div id="progressBar" class="progress-bar bg-default" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            
                <div class="container">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="mb-0">{{$t('configuracoes')}}</h6>
                        </div>
                        <div class="card-body px-0 pt-0">
                            <div class="list-group list-group-flush border-top border-color">
                                <a @click="$router.push('idioma')" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">language</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">{{$t('idioma')}} ({{$i18n.locale}})</h6>
                                            <p class="text-secondary">{{$t('alteraridioma')}}</p>
                                        </div>
                                    </div>
                                </a>
                            
                                <a @click="$router.push('notificacoes')" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">notifications</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">{{$t('notificacoes')}}</h6>
                                            <p class="text-secondary">{{$t('customizeassuasnotificacoes')}}</p>
                                        </div>
                                    </div>
                                </a>                            
                                <a @click="openMaps()" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">location_city</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0 ponteiro">
                                            <h6 class="mb-1">{{$t('enderecodohotel')}}</h6>
                                            <p class="text-secondary">
                                                {{
                                                    $store.getters.GetLoginInfo.hotel.endereco + ' ' +
                                                    $store.getters.GetLoginInfo.hotel.numero + ' ' +
                                                    ($store.getters.GetLoginInfo.hotel.complemento?
                                                    ' - ' + $store.getters.GetLoginInfo.hotel.complemento+' ':'') +
                                                    ($store.getters.GetLoginInfo.hotel.bairro?
                                                    ' - ' + $store.getters.GetLoginInfo.hotel.bairro+' ':'') +
                                                    ($store.getters.GetLoginInfo.hotel.cep?
                                                    ' - ' + $store.getters.GetLoginInfo.hotel.cep+' ':'')
                                                }}<br />{{
                                                    ($store.getters.GetLoginInfo.hotel.cidade?
                                                    $store.getters.GetLoginInfo.hotel.cidade+' ':'') +
                                                    ($store.getters.GetLoginInfo.hotel.ufnome?
                                                    ' - ' + $store.getters.GetLoginInfo.hotel.ufnome+' ':'') +
                                                    ($store.getters.GetLoginInfo.hotel.ufsigla?
                                                    ' - ' + $store.getters.GetLoginInfo.hotel.ufsigla+' ':'')
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <a @click="logOut()" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-danger-light text-danger rounded">
                                                <span class="material-icons">power_settings_new</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">{{$t('sairdosistema')}}</h6>
                                            <p class="text-secondary">{{$t('deslogardoaplicativo')}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import moment from "moment"

export default {
    components: {
        Menu, Header, Footer, Loader
    },
    data() {
        return {
            totalutilizado: '0,00',
            totalpago: '0,00',
            dias: 0,
            reader: new FileReader(),
            fileInput: document.getElementById("file"),
            img: document.getElementById("img"),
            imgHeader: document.getElementById("imgHeader"),
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            avatar: CONSTANTS.API_URL + (this.$store.getters.GetLoginInfo.avatar ? 
                this.$store.getters.GetLoginInfo.avatar : '/img/perfil/P0.png'
            )
        }
    },
    methods: {
        openMaps(){
            var link=document.createElement("a");
            link.id = 'mapsLink';
            var addr = `${this.$store.getters.GetLoginInfo.hotel.endereco},`
            addr += `${this.$store.getters.GetLoginInfo.hotel.numero},`
            addr += `${this.$store.getters.GetLoginInfo.hotel.bairro},`
            addr += `${this.$store.getters.GetLoginInfo.hotel.cidade},`
            addr += `${this.$store.getters.GetLoginInfo.hotel.ufsigla},`
            addr += `${this.$store.getters.GetLoginInfo.hotel.pais}`
            addr = encodeURI(addr.trim())
            link.href=`https://maps.google.com/?q=${addr}`;
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        formatarData(aDate) {
            //return moment(aDate).format('DD-MM-YY HH:MM');
            return moment(aDate).locale(this.$i18n.locale).format('L');
        },        
        toCurrency(x) {
            return x.toFixed(2).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        carregarConsumo() {
            var data = {
                idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                idusuario : this.$store.getters.GetLoginInfo.idhotel,
                numreserva : this.$store.getters.GetLoginInfo.numreserva,
                idhospedepms: this.$store.getters.GetLoginInfo.idhospedepms,
                opcao : "totais"
            };
            var headers = {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/transacao/select', data, {headers: headers})
            .then(response => {
                try {
                    if (response.status == 200) {
                        if (response.data) {
                            var obj = response.data[0];
                            this.totalutilizado = this.toCurrency(obj.totalutilizado);
                            this.totalpago = this.toCurrency(obj.totalpago);
                        } else {
                            alert('else')
                        }
                    } else {
                        alert('erro do backend')
                        alert(JSON.stringify(response.data))
                    }
                }
                catch(e) {
                    alert(e);
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        },
        carregarEstadia() {
            this.dias = this.$store.getters.GetLoginInfo.dias;
            var diasCorridos = this.dias - this.$store.getters.GetLoginInfo.faltam;
            var progressValue = Math.round((diasCorridos / this.$store.getters.GetLoginInfo.dias) * 100).toString();
            var pb = document.getElementById('progressBar');
            pb.style.width =  progressValue.toString()+'%';
        },
        uploadImg(){
            var formData = new FormData()
            var imagefile = document.querySelector('#file')
            formData.append("image", imagefile.files[0])
            formData.append("tipo", "cardapio_produto")
            
            var reader = new FileReader();
            reader.readAsDataURL(imagefile.files[0]);

            reader.onload = e => {
                var img = document.getElementById("img");
                var imgHeader = document.getElementById("imgHeader");
                img.src = e.target.result;
                imgHeader.src = e.target.result;
            }

            axios.post(CONSTANTS.API_URL+"/fdguest/upload/hospede/perfil/"+this.$store.getters.GetLoginInfo.idreservasfront, 
                formData, {headers: this.headers})
            .then(() =>{
              // alert("Atualizado com Sucesso!");
            })
            .catch(() =>{
              alert("Não foi possível Atualizar!");
            })            
        },
        logOut() {
            // liberar memória da sessão (persistedstate)
            sessionStorage.clear();
            this.$router.push('/');
        }
    },
    mounted() {
        this.carregarConsumo();
        this.carregarEstadia();
    }
}
</script>

<style>
#file {
  display: none;
}

#img {
  height: auto;
  max-width: 100%;
}
</style>