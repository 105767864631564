<template>
    <!-- Menu -->
    <div class="main-menu text-center">
        <div
            top
            :close-on-click="closeOnClick"
            :close-on-content-click="closeOnClick"
            absolute
        >
            <div class="my-v-list">
                <!-- FDGuest -->
                <div class="my-v-list-item" @click="closeMenu()">
                    <div>
                        <button id="menu-btn-fechar" class="btn btn-link btn-40 btn-close text-white"><span class="material-icons">chevron_left</span></button>
                        <div class="avatar avatar-30 rounded-circle position-relative">
                            <figure class="background">
                                <img src="../../img/guestfd.png" alt="">
                            </figure>
                        </div>
                        GuestFD
                    </div>
                </div>        
                <!-- Restante do Menu -->
                <div class="my-v-list-item"
                    v-for="(item, index) in itensMenuPrincipal"
                    :key="index"
                    @click="routeTo(item)"
                >
                    <div >
                        <span class="material-icons icon">{{item.icone}}</span>
                        <span class="menu-item-title">{{ item.descricao }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            closeOnClick: true,
            itens: []            
        }
    },
    methods: {
        closeMenu() {
            var by = document.getElementById('menu-principal');
            if (by) {
                if (by.classList.contains('menu-overlay')) {
                    by.classList.remove('menu-open');
                } else {
                    by.classList.remove('menu-active');
                    by.classList.remove('menu-open');
                }
            }
        },
        routeTo(item) {
            this.closeMenu();
            this.$store.dispatch('set_Current_Item', item);
            this.$router.push(item.link);
        }
    },
    mounted() {
        var m = document.getElementById('menu-btn-fechar');
        m.onclick = function () {
            return false;                
        }
        window.scrollTo(0,0);
    },
    computed: {
        itensMenuPrincipal: function () {
            return this.itens.filter(elem => {
                return (elem.menuprincipal == true)
            })
        }
    },
    created() {
        this.itens= this.$store.getters.GetItens
    }
}
</script>

<style scoped>
.my-v-list {
    display: flex;
    flex-direction: column;
}
.my-v-list-item {
    flex-direction: row;
    width:100%;
    padding: 0.5rem;
    text-align: left;
    cursor: pointer;
    font-size: 1.0rem;
    margin: 0.3rem;
}
.my-v-list-item:hover {
    background-color: rgb(27, 139, 224)!important;
    border-radius: 0.2rem;
}
.menu-item-title{
    padding: 0.5rem;
    padding-left: 0.7rem;
}
</style>