<template>
    <div class="card">
        <div class="card-header">
            <div class="text-center">
                <div class="col-12">
                <div v-if="!imagemSrc"><span class="material-icons icon form-control">hotel</span></div>
                <img v-if="imagemSrc" :src="'data:' + imagemTipo + ';base64,' + imagemSrc" 
                    :id="'imgPromo_'+promocao.idpromocao"
                    width="100%"
                />
                </div>
            </div>
        </div>
        <div class="card-body">
            <hr>
            <h6 class="text-mute mb-1">{{promocao.nome}}</h6>
            <h4>{{promocao.titulo}}</h4>
            <span v-if="desc">
                {{promocao.descricao}}
            </span>            
            <h5 class="text-success">R$ {{promocao.valor | formatarMoeda}}</h5>
            <div class="row text-center">
            <div class="col-6">
                <button class="btn btn-md btn-default rounded">{{promocao.desconto | formatarDesconto}}</button>
            </div>
            <div v-if="buy" class="col-6">
                <button @click="$emit('ir')" class="btn btn-default rounded"><i class="material-icons pr-1">local_mall</i>Comprar</button>
            </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'

export default {
    props: {
        promocao: {
            type: Object,
            required: true
        },
        buy: {
            type: Boolean,
            default: true
        },
        desc: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            imagemSrc: null,
            imagemTipo: null
        }
    },
    methods: {
		carregarImagem() {
            if (this.promocao.imagem) {
                this.imagemSrc = null
                this.imagemTipo = null
                axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: this.promocao.imagem }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this.imagemSrc = Buffer.from(imgObj.dados).toString()
                            this.imagemTipo = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},
    },
    mounted() {
        this.carregarImagem()
    }
}
</script>

<style>

</style>