<template>
  <div>
      <Loader />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
        <header class="header">
            <div class="row">
                <div class="col-auto px-0">
                    <button class="btn btn-40 btn-link back-btn" type="button" @click="$router.push('/promocaoparceiro')">
                        <span class="material-icons">keyboard_arrow_left</span>
                    </button>
                </div>
                <div class="text-left col align-self-center">
                    <a class="navbar-brand" >
                        <h5 class="mb-0">Detalhe do Item</h5>
                    </a>
                </div>
            </div>
        </header>
        <div class="main-container">
            <div class="container mb-4">
                <promocao-parceiro-card :promocao="produto" :buy="false" :desc="true"></promocao-parceiro-card>
            </div>
            <div class="container">
                <h5 class="text-success" style="display:none;">{{produto.valor | formatarMoeda}}</h5>
                <hr>
                <!-- Data para agendamento -->
                <div class="row py-2">
                    <div class="col-6">
                        <label class="form-control-label">{{$t('informardata')}}: </label>
                        <input type="date" class="form-control" autofocus="" 
                            v-model="datahoraagendamento">
                    </div>
                    <div class="col-6">
                        <label class="form-control-label">{{$t('informarhorario')}}: </label>
                        <input type="time" class="form-control" autofocus="" v-model="horario">
                    </div>
                </div>
                <div class="row py-2">
                    <div class="col-6">
                        <label class="form-control-label">{{$t('informarquantidade')}}: </label>
                        <div class="input-group cart-count cart-count-lg rounded">
                            <div class="input-group-prepend">
                                <button @click="qtd>0?qtd--:qtd" class="btn btn-outline-secondary rounded" type="button">-</button>
                            </div>
                            <input type="text" class="form-control rounded" placeholder="" :value=qtd>
                            <div class="input-group-append">
                                <button @click=" qtd ++" class="btn btn-outline-secondary rounded" type="button">+</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pt-3 mt-2">
                        <button :disabled="qtd==0&&datahoraagendamento" @click=" solProduto()" class="btn btn-default rounded"><i class="material-icons">local_mall</i> Comprar</button>
                    </div>
                </div>
                <!-- <div class="row py-2 text-right">
                    <div class="col-auto ">
                        <button :disabled="qtd==0&&datahoraagendamento" @click=" solProduto()" class="btn btn-default rounded"><i class="material-icons">local_mall</i> Comprar</button>
                    </div>
                </div> -->
                <hr>
            </div>
        </div>
      </main>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
import PromocaoParceiroCard from './PromocaoParceiroCard.vue'
import moment from 'moment'

export default {
    components: {
        Loader,
        PromocaoParceiroCard,
    },
    data() {
        return {
            produto: null,
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            qtd: '1',
            datahoraagendamento: moment().format('YYYY-MM-DD'),
            horario: '07:00'
        }
    },
    methods: {
        setProduto() {
            this.produto = this.$store.getters.GetCurrentProduto;
        },
        solProduto() {
            var str = this.$store.getters.GetLoginInfo.nome;
            var res = str.split(" ");
            var nome = res[0];
            var sobrenome = (res.length==1)?'':res[res.length-1];
            var datahora = `${this.$d(this.datahoraagendamento).format('DD/MM/YY')} ${this.horario}`
            var texto = `${this.$t('solicitocompra')} ${this.qtd} ${this.$t('unidadespromocao')}:\n${this.produto.descricao} (${this.produto.nome} - ${datahora})`
            var compra = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : this.$store.getters.GetCurrentItem.idhotlinesetores,
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
                "idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" : texto,
				"idtiposolicitacao" : CONSTANTS.TIPO.COMPRA,
                "datahoraagendamento" : `${this.$d(this.datahoraagendamento).format('YYYY-MM-DD')} ${this.horario}`
            };
           
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
            compra, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.$router.push("/sucesso")
                }
            })
        },
    },
    mounted() {
        this.setProduto()
    }

}
</script>

<style>

</style>