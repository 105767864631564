
let domain = (new URL(window.location));
let apiURL = `${domain.protocol}//api${domain.hostname}`
apiURL = apiURL.replace('.hotel.','.')
export default {
    SOCKET_URL: ((process.env.NODE_ENV === 'development') ?  'http://localhost:3331' : apiURL),
    API_URL: ((process.env.NODE_ENV === 'development') ?  'http://localhost:3333' : apiURL),
    // API_URL: ((process.env.NODE_ENV === 'development') ?  'http://localhost:3333' : 'https://hub.esmeraldapraiahotel.com.br'),
    // SOCKET_URL: ((process.env.NODE_ENV === 'development') ?  'http://localhost:3331' : 'https://hub.esmeraldapraiahotel.com.br'),
    // API_URL: ((process.env.NODE_ENV === 'development') ?  'http://localhost:3333' : 'https://api-fdguest.esmeraldapraiahotel.com.br'),
    // SOCKET_URL: ((process.env.NODE_ENV === 'development') ?  'http://localhost:3331' : 'https://api-fdguest.esmeraldapraiahotel.com.br'),

    KEY_GOOGLE_AUTOCOMPLETE_API:'AIzaSyDLXJzzfwaQ25Q3vVfbET_1kflv5hvhsDE',
    ROOT_ITEM: 1,
    TIPO: {
        ACORDAR: 1,
        LATE_CHECKOUT: 2,
        MENSAGEIRO: 3,
        TOALHA_BANHO: 4,
        TOALHA_ROSTO: 5,
        ROUPA_CAMA: 6,
        ARRUMAR: 7,
        AMENITIES: 8,
        FRIGOBAR: 9,
        CAFE: 10,
        MANUTENCAO: 11,
        OPINIAO: 12,
        COMPRA: 13
    }
}
