<template>
  <div :class="`slider-wrapper`" :id="`slider-wrapper-${uniqueId}`">
    <div class="slider" v-if="type === 'img'">
      <div :class="`slider-item`" v-for="(item, idx) in items" :key="idx">
        <img :src="item.media" />
      </div>
    </div>

    <div
      :class="`slider`"
      v-if="type === 'html'"
      :id="`slider-${uniqueId}`"
      :style="cssVars"
      @mousedown="handleGestureStart"
      @mousemove="handleGestureOver"
      @mouseup="handleGestureEnd"
    >
      <slot></slot>
    </div>
    <span class="slider-arrow right material-icons icon icone-filtro ponteiro" @click="toRight()">arrow_circle_right</span>
    <span class="slider-arrow left material-icons icon icone-filtro ponteiro" @click="toLeft()">arrow_circle_left</span>
  </div>
</template>
<script>
function getRandom(max, restriction) {
  if (restriction) {
    let random = Math.floor(Math.random() * max + 1);
    while (restriction.includes(random)) {
      random = Math.floor(Math.random() * max + 1);
      if (!restriction.includes(random)) {
        continue;
      }
    }
    return random;
  } else {
    return Math.floor(Math.random() * max + 1);
  }
}
export default {
  props: {
    items: Array,
    type: String,
    inView: Number,
  },
  data() {
    return {
      start: null,
      change: null,
      uniqueId: getRandom(100),
      slots: [],
    };
  },
  computed: {
    cssVars() {
      return {
        "--inView": (1 / this.inView) * 100 - .5 + "%",
      };
    },
  },
  watch: {
    slot: function () {},
  },
  methods: {
    click() {
      console.log("click");
    },
    handleGestureStart(e) {
      this.start = e.clientX;
    },
    handleGestureOver(e) {
      e.preventDefault();
      let touch = e.clientX;
      this.change = this.start - touch;
    },
    handleGestureEnd(e) {
      let item = document.elementFromPoint(e.clientX, e.clientY);
      let width = item.offsetWidth;
      if (this.change > 0) {
        document.getElementById("slider-" + this.uniqueId).scrollLeft +=
          width;
      } else {
        document.getElementById("slider-" + this.uniqueId).scrollLeft -=
          width;
      }
    },
    toRight(){
      if(window.innerWidth <= 768){
        let width = document.getElementById("slider-" + this.uniqueId).offsetWidth
        document.getElementById("slider-" + this.uniqueId).scrollLeft += width;
        return null
      }
        let width = document.getElementById("slider-" + this.uniqueId).offsetWidth
        document.getElementById("slider-" + this.uniqueId).scrollLeft += width;
    },
    toLeft(){
      if(window.innerWidth <= 768){
        let width = document.getElementById("slider-" + this.uniqueId).offsetWidth
        document.getElementById("slider-" + this.uniqueId).scrollLeft -= width;
        return null
      }
      let width = document.getElementById("slider-" + this.uniqueId).offsetWidth
      document.getElementById("slider-" + this.uniqueId).scrollLeft -= width;
    }
  },
  mounted() {
    this.$slots.default.map((slot) => slot.elm.classList.add("slider-item"));
  },
};
</script>
<style lang="scss" scoped>
.slider-wrapper {
  width: 100%;
  height: fit-content;
  position: relative;
}
.slider {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  @media screen and (max-width: 768px) and (min-width: 360px) {
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none; /*Chrome, Safari and Opera*/
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.slider-item {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: calc(
    var(--inView) 
  ); //porcentagem de acordo com quantos itens quer se ver na tela
  margin-right: 0.5rem;
  user-select: none;

  img {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 0.5rem 0.5rem 1rem 1rem;
  }
}
.slider-item:hover {
  cursor: pointer;
}
.width {
  width: calc(
    var(--inView) - 0.5rem
  ); //porcentagem de acordo com quantos itens quer se ver na tela
}
.card-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 0.5rem;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slider-arrow{
  position: absolute;
  top: 45%;
  font-size: 2rem;
  color: #0DB5ED;
  &:hover{
    cursor: pointer;
  }

  &.left{
    left: 0;
  }
  &.right{
    right: 0;
  }
}
</style>
