import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './store/index'

Vue.use(VueI18n);

const messages = {
    'pt-BR': {
        fechar: 'Fechar',
        apartamento: 'Apartamento',
        nenhumasolicitacao: 'Nenhuma solicitação',
        apagar: 'Apagar',
        suaassinatura: 'Sua assinatura',
        uh: 'UH',
        solicitacoes: 'Solicitações',
        avaliacoes: 'Avaliações',
        avaliacao: 'Avaliação',
        feitaem: 'feita em',
        refazeravaliacao: 'Refazer avaliação',
        fazeravaliacao: 'Fazer avaliação',
        solicitacaocanceladapelohospede: 'Solicitação cancelada pelo hóspede',
        exibirhistorico: 'Exibir histórico',
        ocultarhistorico: 'Ocultar histórico',
        media: 'Média',
        solicitacao: 'Solicitação',
        pendentes: 'Pendentes',
        emAndamento: 'Em Andamento',
        atendidas: 'Atendidas',
        atendida: 'Atendida',
        cancelar: 'Cancelar',
        cancelarSolicitacao: 'Cancelar Solicitação ?',
        certezaCancelarSolicitacao: 'Tem certeza que deseja cancelar esta solicitação?',
        sim: 'Sim',
        nao: 'Não',
        cancelamentoSolicitacao: 'Cancelamento de Solicitação',
        solicitacaoCancelada: 'Solicitação de Hotline cancelada com sucesso!',
        visualizado: 'Visualizado',
        contestar: 'Contestar',
        desejaContestar: 'Deseja contestar o atendimento desta solicitação ?',
        seDesejarExplique: 'Se desejar, entre com um texto explicativo.',
        contestacaoAtendimento: 'Contestação de Atendimento',
        contestadoHotelProvidenciara: 'A contestação do atendimento foi feita! O hotel tomará as devidas providências.',
        ok: 'Ok',
        solicitar: 'Solicitar',
        voltar: 'Voltar',
        desejaativaroservicodespertador: 'Deseja ativar o serviço despertador?',
        setordehotline: 'Setor de Hotline',
        quartoacomodado: 'Quarto acomodado',
        totalutilizadoemservicos: 'Total utilizado em serviços',
        vocejapagou: 'Você já pagou',
        periododaacomodacao: 'Período da acomodação',
        total: 'Total',
        diarias: 'diárias',
        configuracoes: 'Configurações',
        idioma: 'Idioma',
        alteraridioma: 'Alterar idioma',
        notificacoes: 'Notificações',
        customizeassuasnotificacoes: 'Customize as suas notificações',
        enderecodohotel: 'Endereço do Hotel',
        sairdosistema: 'Sair do sistema',
        deslogardoaplicativo: 'Deslogar do aplicativo',
        loginde: 'Login de',
        acesso: 'acesso',
        nomedohospede: 'Nome do Hóspede',
        emaildohospede: 'Email',
        senha: 'Senha',
        esqueceusuasenha: 'Esqueceu sua senha?',
        acessarcomfacebook: 'Acessar com Facebook',
        verifique: 'O acesso ao sistema não foi autorizado. Verifique suas credenciais.',
        solicitarrecepcaohorario: 'Solicitar que a recepção te acorde no horário selecionado',
        informarhorario: 'Informar horário',
        informardata: 'Informar data',
        informarquantidade: 'Informar quantidade',
        confirmarsolicitacao: 'Confirmar Solitação',
        solicitarmensageiro: 'Solicitar ao mensageiro que venha até o seu quarto',
        selecioneositens: 'Selecione os ítens',
        informacaoadicional: 'Informação adicional',
        solicitarlatecheckout: 'Solicitar Late-Checkout (sair após o horário de checkout)',
        informarhorariosaida: 'Informar horário de saída',
        recepcao: 'Recepção',
        perfil: 'Perfil',
        governanca: 'Governança',
        governancatitulo: 'Solicite itens de para a governança do Hotel',
        solicitartoalhabanho: 'Solicitar toalha de banho',
        solicitartoalharosto: 'Solicitar toalha de rosto',
        solicitarroupacama: 'Solicitar roupa de cama',
        solicitaramenities: 'Solicitar amenities',
        sabonete: ' Sabonete ',
        shampoo: ' Shampoo ',
        condicionador: ' Condicionador ',
        touca: 'Touca',
        papelhigienico: 'Papel Higiênico',
        itenscovid: 'Ítens Covid',
        servicoquarto: 'Serviço de Quarto',
        servicoquartoitem: 'Ative o item de acordo com o serviço desejado',
        naopertube: 'Não Pertube',
        de: 'De',
        ate: 'Até às',
        escolhaumidioma: 'Escolha um idioma',
        selecioneoidiomadepreferencia: 'Selecione o idioma de preferência',
        notificacao: 'Notificação',
        transacoes: 'Transações',
        minhassolicitacoes: 'Minhas Solicitações',
        todosositenssolicitados: 'Todos os itens solicitados',
        maisitens: 'Mais itens',
        ultimastransacoes: 'Últimas Transações',
        vertodas: 'Ver todas',
        quarto: 'Quarto',
        varanda: 'Varanda',
        fechadura: 'Fechadura',
        serviconoquarto: 'Serviço no Quarto',
        ativeoitemdeacordocomoservicodesejado: 'Ative o item de acordo com o serviço desejado',
        limparquarto: 'Limpar Quarto',
        ateas: 'até:',
        conveniencianohotel: 'Conveniência no Hotel',
        espacoseservicosdisponivelnohotel: 'Espaços e serviços disponíveis no hotel',
        spa: 'Spa',
        consultarservico: 'Consultar Serviço',
        estacionamento: 'Estacionamento',
        acessogratuito: 'Acesso Gratuito',
        piscina: 'Piscina',
        academia: 'Academia',
        manutencao: 'Manutenção',
        manutencaotitulo: 'Informe abaixo caso esteja precisando de manutenção em algum item na sua acomodação',
        itemdefeito: 'Marque o item que apresenta defeito e descreva o ocorrido',
        banheiro: 'Banheiro',
        cofre: 'Cofre',
        atencaocofre: 'Atenção! Para este tipo de solicitação, o hóspede deverá estar presente.',
        telefone: 'Telefone',
        arcondicionado: 'Ar-condicionado',
        tv: 'TV',
        iluminacao: 'Iluminação',
        enviarsoliticacao: 'Enviar Soliticação',
        restaurante: 'Restaurante',
        restaurantetitulo: 'Veja nosso cardápio digital e solicite suas refeições no quarto ou no restaurante.',
        acessarmenu: 'Acessar o Menu Digital',
        menupedido: 'Acesse nosso menu e faça o seu pedido',
        solicitarcafedamanha:'Solicitar café da manhã',
        atencaocafe: 'Atenção, esta solicitação está sujeita a cobrança.',
        cafedamanhapedido: 'Faça seu pedido e escolha o horário desejado',
        reservademesa: 'Reserva de Mesa',
        mesapedido: 'Acesse e escolha o horário desejado',
        reporfrigobar: 'Solicitar reposição de frigobar',
        frigobartitulo: 'Repor itens de frigobar',
        solicitaroutrositens: 'Outros itens do restaurante',
        talheres: 'Talheres',
        copos: 'Copos',
        tacas: 'Taças',
        pratos: 'Pratos',
        abridordegarrafa: 'Abridor de garrafas',
        periodo: 'Informar o período',
        cafedamanha: 'Café da Manhã',
        gerencia: 'Gerência',
        gerenciatitulo: 'Conte-nos o que achou do nosso hotel e dos nossos serviços',
        suaopiniao: 'Sua opinião é muito importante para nós! Preencha os campos abaixo de acordo com seu grau de satisfação.',
        excelente: 'Excelente',
        otimo: 'Ótimo',
        bom: 'Bom',
        regular: 'Regular',
        insatisfatorio: 'Insatisfatorio',
        estruturahotel: 'Estrutura do Hotel',
        qualidadeatendimento: 'Qualidade do Atendimento',
        qualidadeacomodacao: 'Qualidade da Acomodação',
        descrevahotelservicos: 'Descreva sobre o que achou do hotel e seus serviços',
        enviar: 'Enviar',
        consultetelefonesempresasparceiras: 'Consulte, aqui, telefones de empresas parceiras.',
        semempresasparceiras: 'Sem registro de empresas parceiras agora. Volte em outro momento.',
        semempresasfiltro: 'Sem registros. Verifique os filtros.',
        concierge: 'Concierge',
        atencaoservicos: 'Atenção! Os serviços são de responsabilidade de cada empresa.',
        selecioneitensfiltrar: 'Selecione um dos itens para filtrar',
        passeios: 'Passeios',
        taxi: 'Táxis',
        fastfood: 'Fast Foods',
        farmacia: 'Farmácias',
        conveniencia: 'Convêniencias',
        supermercado: 'Supermercados',
        postos: 'Postos',
        salaospa: 'Salão e SPA',
        salaotitulo: 'Consulte os serviços disponíveis no nosso salão de beleza e SPA',
        salaodebeleza: 'Salão de Beleza',
        sauna: 'Sauna',
        sobrehotel: 'Sobre o Hotel',
        sobretitulo: 'Conheça mais sobre o nosso Hotel',
        website: 'Website',
        conhecahotel: 'Conheça mais sobre o hotel',
        cidade: 'Cidade',
        conhecacidade: 'Conheça mais sobre a cidade',
        mapa: 'Mapa',
        promocoes: 'Promoções',
        reservas: 'Reservas',
        reserva: 'Reserva',
        reservastitulo: 'Consulte nossas promoções ou entre em contato com nosso setor de reservas',
        sempromocaotitulo: 'Sem promoções no momento!',
        sempromocaotexto: 'Mas não desista! Volte, pois a qualquer momento você poderá encontrar novas promoções aqui.',
        ofertaspromocoes: 'Ofertas e Promoções',
        acessenossapagina: 'Acesse nossa página de promoções',
        ligarreservas: 'Ligar para setor de Reservas',
        falarreservas: 'Fale com nosso setor de reservas',
        conversarwhatsapp: 'Conversar via Whatsapp',
        atendimentoreservaswhatsapp: 'Atendimento de reservas pelo whatsapp',
        enviaremailreservas: 'Enviar e-mail setor de Reservas',
        acompanhetodosastransacoesfinanceirasrealizadasduranteasuahospedagem: 'Acompanhe todos as transações financeiras realizadas durante a sua hospedagem',
        subtotal: 'Sub total',
        desconto: 'Desconto',
        totalapagar: 'Total a pagar',
        sucesso: 'Sucesso',
        solicitacaoregistradacomsucesso: 'Sua solicitação foi registrada com sucesso!',
        breveatendemosopedido: 'Estamos cientes do seu pedido que será atendido mediante disponibilidade!',
        direcionadopaginainicial: 'Você será direcionado para a página inicial.',
        naoconcluido: 'Não Concluído!',
        solicitacaonaoenviada: 'A solicitação não foi enviada!',
        facanovasolicitacao: 'Por favor, faça uma nova solicitação!',
        porfavoracordaras: 'Por favor, me acordar as ',
        porfavorlatecheckout: 'Por favor, solicito um late checkout às ',
        favorlimpar: 'Favor limpar o quarto entre ',
        favornaopertubar: 'Favor não pertubar entre ',
        e: 'e',
        gostariasolicitar: 'Gostaria de solicitar',
        toalhabanho: ' Toalha(s) de Banho',
        toalharosto: ' Toalha(s) de Rosto',
        roupacama: ' Roupa de Cama',
        solicitoreparo: 'Por favor, solicito reparo nesse(s) iten(s) no meu quarto',
        problemadeles: ' O problema deles é',
        porfavorreposicaofrigobar: 'Por favor, gostaria de solicitar a reposição do meu frigobar',
        horarioentre: ' no horário entre ',
        notaestrutura: 'Nota pela estrutura do hotel',
        notaatendimento: 'Nota pela qualidade do atendimento',
        notaacomodacao: 'Nota pela acomodação',
        observacoes: 'E minhas observações',
        solicitocompra: 'Solicito a compra de ',
        unidadespromocao: ' Unidades da promoção ',
        acompanhetodosositenssolicitados: 'Acompanhe todos os itens solicitados',
        vejaasavaliacoesquejafezdohotel: 'Veja as avaliações que já fez do hotel',
        acompanhetodasassuasnofificacoes: 'Acompanhe todas as suas nofificações',
        descrevacontestacao: 'Descreva o motivo da contestação',
        confirmarcontestacao: 'Confirmar Contestação',
        descrevacancelamento: 'Descreva o motivo do cancelamento',
        confirmarcancelamento: 'Confirmar Cancelamento',
        resposta: {
            ruim: 'Ruim',
            regular: 'Regular',
            bom: 'Bom',
            otimo: 'Ótimo',
            excelente: 'Excelente'
        }
    },
    'en': {
        fechar: 'Close',
        apartamento: 'Room',
        nenhumasolicitacao: 'No request',
        apagar: 'Delete',
        suaassinatura: 'Your signature',
        uh: 'HU',
        solicitacoes: 'Requests',
        avaliacoes: 'Assessments',
        avaliacao: 'Assessment',
        feitaem: 'done on',
        refazeravaliacao: 'Assess again',
        fazeravaliacao: 'Make assessment',
        solicitacaocanceladapelohospede: 'Request canceled by guest',
        exibirhistorico: 'Show history',
        ocultarhistorico: 'Hide history',
        media: 'Average',
        solicitacao: 'Request',
        pendentes: 'Pending',
        emAndamento: 'In Progress',
        atendidas: 'Fulfilled',
        atendida: 'Fulfilled',
        cancelar: 'Cancel',
        cancelarSolicitacao: 'Cancel Request ?',
        certezaCancelarSolicitacao: 'Are you sure you want to cancel this request?',
        sim: 'Yes',
        nao: 'No',
        cancelamentoSolicitacao: 'Request Cancellation',
        solicitacaoCancelada: 'Hotline request canceled successfully!',
        visualizado: 'Viewed',
        contestar: 'Contest',
        desejaContestar: 'Do you want to dispute the fulfillment of this request?',
        seDesejarExplique: 'If you wish, enter an explanatory text.',
        contestacaoAtendimento: 'Service Contest',
        contestadoHotelProvidenciara: 'The contestation of the attendance was done! The hotel will take appropriate measures.',
        ok: 'Ok',
        solicitar: 'Request',
        voltar: 'Back',
        desejaativaroservicodespertador: 'Do you want to activate the alarm service?',
        setordehotline: 'Hotline Sector',
        quartoacomodado: 'Room',
        totalutilizadoemservicos: 'Total used in services',
        vocejapagou: 'You already paid',
        periododaacomodacao: 'Accommodation period',
        total: 'Total',
        diarias: 'days',
        configuracoes: 'Settings',
        idioma: 'Language',
        alteraridioma: 'Change language',
        notificacoes: 'Notifications',
        customizeassuasnotificacoes: 'Customize your notifications',
        enderecodohotel: 'Hotel Address',
        sairdosistema: 'Exit the system',
        deslogardoaplicativo: 'Log out of the app',
        loginde: 'Login',
        acesso: '',
        nomedohospede: 'Guest name',
        emaildohospede: 'Email',
        senha: 'Password',
        esqueceusuasenha: 'Forgot your password?',
        acessarcomfacebook: 'Login with Facebook',
        verifique: 'Access to the system has not been authorized. Please, check your credentials.',
        solicitarrecepcaohorario: 'Request the front desk a wake up call in the selected hour',
        informarhorario: 'Hour to Wake up',
        informardata: 'Inform the date',
        informarquantidade: 'Inform quantity',
        confirmarsolicitacao: 'Request confirmation',
        solicitarmensageiro: 'Request the bellboy the comes to your room',
        selecioneositens: 'Select items',
        informacaoadicional: 'Additional information',
        solicitarlatecheckout: 'Late-Checkout request(leave after checkout hour)',
        informarhorariosaida: 'Informe the exit hour',
        recepcao: 'Front Desk',
        perfil: 'Profile',
        governanca: 'Governance',
        governancatitulo: 'Request items for the hotel governance',
        solicitartoalhabanho: 'Request a bath towel',
        solicitartoalharosto: 'Request a face towel',
        solicitarroupacama: 'Request a bed linen',
        solicitaramenities: 'Request amenities',
        sabonete: 'Soap',
        shampoo: 'Shampoo',
        condicionador: 'Conditioner',
        touca: 'Cap',
        papelhigienico: 'Toilet Paper',
        itenscovid: 'Covid Items',
        servicoquarto: 'Room Service',
        servicoquartoitem : 'Activate the item according to the desired item',
        naopertube: 'Dont Disturb',
        limparquarto: 'Clean the room',
        de: 'From',
        ate: 'At',
        escolhaumidioma: 'Choose one language',
        selecioneoidiomadepreferencia: 'Select the preferred language',
        notificacao: 'Notification',
        transacoes: 'Transactions',
        minhassolicitacoes: 'My Requests',
        todosositenssolicitados: 'All requested items',
        maisitens: 'More itens',
        ultimastransacoes: 'Latest Transactions',
        vertodas: 'View all',
        quarto: 'Room',
        varanda: 'Balcony',
        fechadura: 'Door lock',
        serviconoquarto: 'In-Room Service',
        ativeoitemdeacordocomoservicodesejado: 'Activate the item according to the desired service',
        ateas: 'To',
        conveniencianohotel: 'Hotel Convenience',
        espacoseservicosdisponivelnohotel: 'Spaces and services available at the hotel',
        spa: 'Spa',
        consultarservico: 'Consult Service',
        estacionamento: 'Parking',
        acessogratuito: 'Free Access',
        piscina: 'Swimming pool',
        academia: 'Gym',
        manutencao: 'Maintenance',
        manutencaotitulo: 'Tell us if you need a maintenance in any items of your room',
        itemdefeito: 'Choose the item who presents a defect and describe the occurrence',
        banheiro: 'Bathroom',
        cofre: 'Safe Box',
        atencaocofre: 'Attention! For this type of request, the guest must be present.',
        telefone: 'Telephone',
        arcondicionado: 'Air conditioning',
        tv: 'TV',
        iluminacao: 'Lights',
        enviarsoliticacao: 'Send Request',
        restaurante: 'Restaurant',
        restaurantetitulo: 'Check our digital menu and request your meal in the room or in restaurant.',
        acessarmenu: 'Access the Digital Menu',
        menupedido: 'Access our menu and make your requests',
        solicitarcafedamanha: 'Request the Breakfast',
        atencaocafe: 'Please note, this request is subject to a charge.',
        cafedamanhapedido: 'Make your order and choose the hour of your breakfast',
        reservademesa: 'Table reservation',
        mesapedido: 'Choose the table and the hour for your reservation',
        reporfrigobar: 'Request a frigobar items repositions',
        frigobartitulo: 'Reset frigobar items',
        solicitaroutrositens: 'Other restaurant items',
        talheres: 'Cutlery',
        copos: 'Glass',
        tacas: 'Wine Glasses',
        pratos: 'Plates',
        abridordegarrafa: 'Bottle opener',
        periodo: 'Enter the period',
        cafedamanha: 'Breakfast',
        gerencia: 'Management',
        gerenciatitulo: 'Tell us what you thinking about our hotel and our services',
        suaopiniao: 'Your opinion is very important to us, fill in the fields below according to your level of satisfaction.',
        excelente: 'Excellent',
        otimo: 'Great',
        bom: 'Good',
        regular: 'Regular',
        insatisfatorio: 'Unsatisfactory',
        estruturahotel: 'Hotel structure',
        qualidadeatendimento: 'Attendance Quality',
        qualidadeacomodacao: 'Accommodation Quality',
        descrevahotelservicos: 'Describe what you think about our hotel and our services',
        enviar: 'Send',
        consultetelefonesempresasparceiras: 'Consult, here, phones of our partner companies.',
        semempresasparceiras: 'No partner company registration now. Come back at another time.',
        semempresasfiltro: 'No record. Verify the filters.',
        concierge: 'Concierge',
        atencaoservicos: 'Attention! The services are the responsibility of each company.',
        selecioneitensfiltrar: 'Select one of the items to apply a filter',
        passeios: 'Tours',
        taxis: 'Cabs',
        fastfood: 'Fast Foods',
        farmacia: 'Pharmacy',
        conveniencia: 'Convenience',
        supermercado: 'Supermarket',
        postos: 'Gás Station',
        salaospa: 'Saloon and Spa',
        salaotitulo: 'Consult the services available in our Beauty Salon and Spa',
        salaodebeleza: 'Beauty Salon',
        sauna: 'Steam Room',
        sobrehotel: 'About the Hotel',
        sobretitulo: 'Know more about our hotel',
        website: 'Website',
        conhecahotel: 'Know more about the hotel',
        cidade: 'City',
        conhecacidade: 'Know more about the City',
        mapa: 'Map',
        promocoes: 'Promotions',
        reservas: 'Reservations',
        reserva: 'Reservation',
        reservastitulo: 'See ours promotions or contact- with our reservations sector',
        sempromocaotitulo: 'No promotions at the moment!',
        sempromocaotexto: 'But don\'t give up! Come back, because at any time you can find new promotions here.',
        ofertaspromocoes: 'Offers and promotions',
        acessenossapagina: 'Access our page of promotions',
        ligarreservas: 'Call for the Reservations sector',
        falarreservas: 'Talk with the reservations sector',
        conversarwhatsapp: 'Whatsapp Talk',
        atendimentoreservaswhatsapp: 'Reservations attendance by Whatsapp',
        enviaremailreservas: 'Send an e-mail for the Reservation sector',
        acompanhetodosastransacoesfinanceirasrealizadasduranteasuahospedagem: 'Track all financial transactions made during your stay',
        subtotal: 'Sub total',
        desconto: 'Discount',
        totalapagar: 'Total payable',
        sucesso: 'Success',
        solicitacaoregistradacomsucesso: 'Your request has been successfully registered!',
        breveatendemosopedido: 'We are aware of your request that will be attended to upon availability!',
        direcionadopaginainicial: 'You will be redirected for the home page.',
        naoconcluido: 'Not concluded',
        solicitacaonaoenviada: 'The request dont be send!',
        facanovasolicitacao: 'Please, do it a new request!',
        porfavoracordaras: "Please, wake me up at ",
        porfavorlatecheckout: "Please, give me a late checkout at ",
        favorlimpar: 'Please, clean the room between ',
        favornaopertubar: 'Please dont disturb between ',
        e: 'and',
        gostariasolicitar: 'I would like to request',
        toalhabanho: ' Bath Towel',
        toalharosto: ' Face Towel',
        roupacama: ' Bed Linen',
        solicitoreparo: 'Please, I request repair of these item (s) in my room',
        problemadeles: ' And the problem is',
        porfavorreposicaofrigobar: 'Please, I would like to request frigobar items replacement',
        horarioentre: ' in the time between ',
        notaestrutura : 'Score for the hotel structure',
        notaatendimento: 'Score about attendant quality',
        notaacomodacao: 'Score about the room',
        observacoes: 'And my observations',
        solicitocompra: 'Request a buy of ',
        unidadespromocao: ' Promotional unity ',
        acompanhetodosositenssolicitados: 'Track all requested items',
        vejaasavaliacoesquejafezdohotel: 'Track all your assessments made',
        acompanhetodasassuasnofificacoes: 'Track all your notifications',
        cotestar: 'Dispute',
        descrevacontestacao: 'Describe the reason for the dispute',
        confirmarcontestacao: 'Dispute Confirmation',
        descrevacancelamento: 'Describe the reason for cancellation',
        confirmarcancelamento: 'Confirm Cancellation',
        resposta: {
            ruim: 'Bad',
            regular: 'Regular',
            bom: 'Good',
            otimo: 'Great',
            excelente: 'Excelent'
        }
    },
    'es': {
        fechar: 'Cerrar',
        apartamento: 'Habitación',
        nenhumasolicitacao: 'Sin solicitud',
        suaassinatura: 'Tu firma',
        apagar: 'Eliminar',
        uh: 'UH',
        solicitacoes: 'Peticiones',
        avaliacoes: 'Evaluaciones',
        avaliacao: 'Evaluacion',
        feitaem: 'realizada el',
        refazeravaliacao: 'Evaluar de nuevo',
        fazeravaliacao: 'Hacer evaluación',
        solicitacaocanceladapelohospede: 'Solicitud cancelada por invitado',
        exibirhistorico: 'Mostrar historial',
        ocultarhistorico: 'Ocultar historial',
        media: 'Média',
        solicitacao: 'Peticione',
        pendentes: 'Pendiente',
        emAndamento: 'En Proceso',
        atendidas: 'Cumplidas',
        atendida: 'Cumplida',
        cancelar: 'Cancelar',
        cancelarSolicitacao: '¿Cancelar petición?',
        certezaCancelarSolicitacao: '¿Está seguro de que desea cancelar esta solicitud?',
        sim: 'Si',
        nao: 'No',
        cancelamentoSolicitacao: 'Cancelación de Peticione',
        solicitacaoCancelada: '¡Solicitud de línea directa cancelada con éxito!',
        visualizado: 'Visto',
        contestar: 'Disputar',
        desejaContestar: '¿Quiere disputar el cumplimiento de esta solicitud?',
        seDesejarExplique: 'Si lo desea, introduzca un texto explicativo.',
        contestacaoAtendimento: 'Servicio en disputa',
        contestadoHotelProvidenciara: '¡Se hizo la impugnación de la asistencia! El hotel tomará las medidas necesarias.',
        ok: 'Ok',
        solicitar: 'Solicitar',
        voltar: 'Vuelve',
        desejaativaroservicodespertador: '¿Quieres activar el servicio de alarma?',
        setordehotline: 'Setor de Hotline',
        quartoacomodado: 'Habitación',
        totalutilizadoemservicos: 'Total utilizado en servicios',
        vocejapagou: 'Ya pagaste',
        periododaacomodacao: 'Periodo de alojamiento',
        total: 'Total',
        diarias: 'dias',
        configuracoes: 'Ajustes',
        idioma: 'Idioma',
        alteraridioma: 'Cambia el idioma',
        notificacoes: 'Notificaciones',
        customizeassuasnotificacoes: 'Personaliza tus notificaciones',
        enderecodohotel: 'Dirección del hotel',
        sairdosistema: 'Salir del sistema',
        deslogardoaplicativo: 'Cerrar sesión en la aplicación',
        loginde: 'Iniciar',
        acesso: 'sesión',
        nomedohospede: 'Nombre del invitado',
        emaildohospede: 'Email',
        senha: 'Contraseña',
        esqueceusuasenha: '¿Olvidaste tu contraseña?',
        acessarcomfacebook: 'Iniciar sesión con Facebook',
        verifique: 'No se ha autorizado el acceso al sistema. Verifique sus credenciales.',
        solicitarrecepcaohorario: 'Solicite a la recepción una llamada de despertador a la hora seleccionada',
        informarhorario: 'Hora para acordar',
        informardata: 'Informar la fecha',
        informarquantidade: 'Informar cantidad',
        confirmarsolicitacao: 'Solicitar confirmación',
        solicitarmensageiro: 'Solicita el mensajero que viene a tu habitación.',
        selecioneositens: 'Seleccionar elementos',
        informacaoadicional: 'Información adicional',
        solicitarlatecheckout: 'Solicitar check-out tardio (sair após o horário de check-out)',
        informarhorariosaida: 'Informe la hora de salida',
        recepcao: 'Recepción',
        perfil: 'Perfil',
        governanca: 'Gobernancia',
        governancatitulo: 'Solicitar elementos para la gobernanza del hotel',
        solicitartoalhabanho: 'Solicita una toalla de baño',
        solicitartoalharosto: 'Solicita una toalla facial',
        solicitarroupacama: 'Solicite una ropa de cama',
        solicitaramenities: 'Solicite Amenities',
        sabonete: 'Jabón',
        shampoo: 'Shampoo',
        condicionador: 'Acondicionador',
        touca: 'Gorro',
        papelhigienico: 'Papel Higiénico',
        itenscovid: 'Artículos Covid',
        servicoquarto: 'Servicio de habitacion',
        servicoquartoitem: 'Activar el artículo según el artículo deseado',
        naopertube: 'No molestar',
        de: 'De',
        ate: 'a',
        escolhaumidioma: 'Escolha um idioma',
        selecioneoidiomadepreferencia: 'Selecione o idioma de preferencia',
        notificacao: 'Notificación',
        transacoes: 'Transacciones',
        minhassolicitacoes: 'Mis Solicitudes',
        todosositenssolicitados: 'Todos los artículos pedidos',
        maisitens: 'Más itens',
        ultimastransacoes: 'Últimas Transacciones',
        vertodas: 'Ver todo',
        quarto: 'Habitación',
        varanda: 'Balcón',
        fechadura: 'Cerradura de la puerta',
        serviconoquarto: 'Servicio a la habitacion',
        ativeoitemdeacordocomoservicodesejado: 'Activar el artículo según el servicio deseado',
        limparquarto: 'Limpiar la habitación',
        ateas: 'A',
        conveniencianohotel: 'Conveniencia del hotel',
        espacoseservicosdisponivelnohotel: 'Espacios y servicios disponibles en el hotel',
        spa: 'Spa',
        consultarservico: 'Consultar servicio',
        estacionamento: 'Estacionamiento',
        acessogratuito: 'Acceso libre',
        piscina: 'Piscina',
        academia: 'Gimnasio',
        manutencao: 'Mantenimiento',
        manutencaotitulo: 'Díganos si necesita un mantenimiento en algún elemento de su habitación',
        itemdefeito: 'Elija el artículo que presenta un defecto y describa la ocurrencia',
        banheiro: 'WC',
        cofre: 'Caja Furte',
        atencaocofre: '¡Atención! Para este tipo de solicitud, el invitado debe estar presente.',
        telefone: 'Teléfono',
        arcondicionado: 'Aire condicionado',
        tv: 'TV',
        iluminacao: 'Iluminación',
        enviarsoliticacao: 'Enviar petición',
        restaurante: 'Restaurante',
        restaurantetitulo: 'Consulta nuestro menú digital y solicita tu comida en la habitación o en restaurante.',
        acessarmenu: 'Acceder al menú digital',
        menupedido: 'Accede a nuestro menú y realiza tus solicitudes',
        solicitarcafedamanha: 'Solicita el Desayuno',
        atencaocafe: 'Tenga en cuenta que esta solicitud está sujeta a un cargo.',
        cafedamanhapedido: 'Haz tu pedido y elige la hora de tu desayuno',
        reservademesa: 'Reserva de Mesa',
        mesapedido: 'Elija la mesa y la hora de su reserva',
        reporfrigobar: 'Solicitar reposiciones de artículos de frigobar',
        frigobartitulo: 'Restablecer elementos del refrigerador',
        solicitaroutrositens: 'Otros artículos de restaurante',
        talheres: 'Cuchillería',
        copos: 'Vidrios',
        tacas: 'Copas de vino',
        pratos: 'Platos',
        abridordegarrafa: 'Abridor de botellas',
        periodo: 'Ingrese el período',
        cafedamanha: 'Desayuno',
        gerencia: 'Administración',
        gerenciatitulo: 'Cuéntanos que te parece nuestro hotel y nuestros servicios',
        suaopiniao: 'Tu opinión es muy importante para nosotros, rellena los campos a continuación según tu nivel de satisfacción',
        excelente: 'Excelente',
        otimo: 'Genial',
        bom: 'Bueno',
        regular: 'Regular',
        insatisfatorio: 'Insatisfactorio',
        estruturahotel: 'Estructura del hotel',
        qualidadeatendimento: 'Calidad de Asistencia',
        qualidadeacomodacao: 'Calidad del alojamiento',
        descrevahotelservicos: 'Describe lo que piensas de nuestro hotel y nuestros servicios',
        enviar: 'Enviar',
        consultetelefonesempresasparceiras: 'Consultar por acá teléfonos de empresas colaboradoras.',
        semempresasparceiras: 'No hay registro de empresa asociada ahora. Vuelve en otro momento.',
        semempresasfiltro: 'No hay registros. Verificar los filtros',
        concierge: 'Concierge',
        atencaoservicos: '¡Atención! Los servicios son responsabilidad de cada empresa.',
        selecioneitensfiltrar: 'Seleccione uno de los elementos para aplicar un filtro',
        passeios: 'Excursiones',
        taxi: 'Taxis',
        fastfood: 'Fast Foods',
        farmacia: 'Farmacia',
        conveniencia: 'Conveniencia',
        supermercado: 'Supermercado',
        posto: 'Gasolinera',
        salaospa: 'Salón y Spa',
        salaotitulo: 'Consulte los servicios disponibles en nuestro Salón de Belleza y Spa',
        salaodebeleza: 'Salón de belleza',
        saunha: 'Cuarto de vapor',
        sobtehotel: 'Sobre el hotel',
        sobrehotel: 'Conozca más sobre nuestro hotel',
        website: 'Website',
        conhecahotel:'Conozca más sobre o hotel',
        cidade:'Ciudad',
        conhecadidade: 'Conozca más sobre a ciudad',
        mapa: 'Mapa',
        promocoes: 'Promociones',
        reservas: 'Reservaciones',
        reserva: 'Reservación',
        reservastitulo: 'Vea nuestras promociones o contacte- con nuestro sector de reservas',
        sempromocaotitulo: '¡No hay promociones por el momento!',
        sempromocaotexto: '¡Pero no te rindas! Vuelve, porque en cualquier momento puedes encontrar nuevas promociones aquí.',
        ofertaspromocoes: 'Ofertas y promociones',
        acessenossapagina: 'Accede a nuestra página de promociones',
        ligarreservas: 'Llame al sector de reservas',
        falarreservas: 'hablar con el sector de reservas',
        conversawhatsapp: 'Charla Whatsapp',
        atendimentoreservaswhatsapp: 'Asistencia de reserva por whatsapp',
        enviaremailreservas: 'Envíe un correo electrónico para el sector de reservas',
        acompanhetodosastransacoesfinanceirasrealizadasduranteasuahospedagem: 'Seguimiento de todas las transacciones financieras realizadas durante su estadía',
        subtotal: 'Sub total',
        desconto: 'Descuento',
        totalapagar: 'Total a pagar',
        sucesso:'Éxito',
        solicitacaoregistradacomsucesso: '¡Su solicitud ha sido registrada con éxito!',
        breveatendemosopedido: '¡Estamos al tanto de su solicitud que será atendida según disponibilidad!',
        direcionadopaginainicial: 'Se le redirigirá a la página de inicio.',
        naoconcluido: 'No concluido',
        solicitacaonaoenviada: 'La solicitud no se enviará',
        facanovasolicitacao: 'Por favor, hazlo una nueva solicitud.',
        porfavoracordaras: 'Por favor, despiértame a las ',
        porfavorlatecheckout: 'Por favor, dame una salida tardía en ',
        favorlimpar: 'Por favor, limpie la habitación entre ',
        favornaopertubar: 'Por favor, no molestes entre ',
        e: 'e',
        gostariasolicitar: 'Me gustaría solicitar ',
        toalhabanho: ' Toalla de baño',
        toalharosto: ' Toalla facil',
        roupacama: ' Roupa de cama',
        solicitoreparo: 'Por favor, solicito la reparación de estos artículos en mi habitación',
        problemadeles: ' Y ellos problemas es',
        porfavorreposicaofrigobar: 'Por favor, me gustaría reemplazar los artículos del frigobar',
        horarioentre: ' en el tiempo entre ',
        notaestrutura: 'La puntuación para la estructura del hotel',
        notaatendimento: 'La puntuación sobre la calidad del asistente',
        notaacomodacao: 'La puntuación de la habitación',
        observacoes: 'Y mis observaciones',
        solicitocompra: 'Solicite uma compra de ',
        unidadespromocao: ' Unidad promocional ',
        acompanhetodosositenssolicitados: 'Seguimiento de todos los artículos solicitados',
        vejaasavaliacoesquejafezdohotel: 'Realice un seguimiento de todas sus evaluaciones realizadas',
        acompanhetodasassuasnofificacoes: 'Seguimiento de todas sus notificaciones',
        descrevacontestacao: 'Describe el motivo de la disputa',
        confirmarcontestacao: 'Confirmación de disputa',
        descrevacancelamento: 'Describe el motivo de la cancelación.',
        confirmarcancelamento: 'Confirmar cancelación',
        resposta: {
            ruim: 'Malo',
            regular: 'Regular',
            bom: 'Bien',
            otimo: 'Muy bien',
            excelente: 'Estupendo'
        }
    }
};

// const numberFormats = {
//     'en-US': {
//       currency: {
//         style: 'currency', currency: 'USD', currencyDisplay: 'symbol', useGrouping: false
//       }
//     },
//     'pt-BR': {
//         currency: {
//           style: 'currency', currency: 'R$', currencyDisplay: 'symbol', useGrouping: false
//         }
//       },
//       'ja-JP': {
//       currency: {
//         style: 'currency', currency: 'JPY', currencyDisplay: 'name', 
//         abbreviate: true, // custom property to determinate whether apply special styles
//         maximumSignificantDigits: 4, useGrouping: true
//       }
//     }
// }

const i18n = new VueI18n({    
    locale: store.getters.GetDefaultLanguage || 'pt-BR', 
    fallbackLocale: ['es','en'],
    messages,
    // numberFormats
});

export default i18n
