import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store (
    {
        plugins: [createPersistedState({
            storage: window.sessionStorage,
        })],
        state: {
            componentName: '',
            currentItem: null,
            itens: [],
            totalSols: 0,
            solsPendentes: [],
            solsEmAndamento: [],
            solsAtendidas: [],
            defaultLanguage: 'pt-BR',
            //defaultLanguage: loadDefaultLanguage(),
            suportedLanguages: [],
            loginInfo: null,
            notaAtual: null,
            notificacoes: [],
            currentProduto:null,
            ultimasTransacoes: [],
            primeirosItens: [],
            demaisItens: [],
            rootItem: null,
            avaliacao: 0,
            perguntas: [],
            idParceiro: 0
        },
        mutations: {
            setComponentName(state, payload) {
                state.componentName = payload
            },
            setCurrentItem(state, payload) {
                state.currentItem = payload
            },
            setCurrentProduto(state, payload) {
                state.currentProduto = payload
            },
            setItens(state, payload) {
                state.itens = payload
            },
            setTotalSols(state, payload) {
                state.totalSols = payload
            },
            setSolsPendentes(state, payload) {
                state.solsPendentes = payload
            },
            setSolsEmAndamento(state, payload) {
                state.solsEmAndamento = payload
            },
            setSolsAtendidas(state, payload) {
                state.solsAtendidas = payload
            },
            setDefaultLanguage(state, payload) {
                state.defaultLanguage = payload
            },
            setSuportedLanguages(state, payload) {
                state.suportedLanguages = payload
            },
            setLoginInfo(state, payload) {
                state.loginInfo = payload
            },
            setNotaAtual(state, payload) {
                state.notaAtual = payload
            },
            setNotificacoes(state, payload) {
                state.notificacoes = payload
            },
            setUltimasTransacoes(state, payload) {
                state.ultimasTransacoes = payload
            },
            setPrimeirosItens(state, payload) {
                state.primeirosItens = payload
            },
            setDemaisItens(state, payload) {
                state.demaisItens = payload
            },
            setRootItem(state, payload) {
                state.rootItem = payload
            },
            setAvaliacao(state, payload) {
                state.avaliacao = payload
            },
            setPerguntas(state, payload) {
                state.perguntas = payload
            },
            setIdParceiro(state, payload) {
                state.idParceiro = payload
            }
        },
        actions: {
            set_Component_Name({commit}, payload ) {
                commit('setComponentName', payload)
            },
            set_Current_Item({commit}, payload ) {
                commit('setCurrentItem', payload)
            },
            set_Current_produto({commit}, payload ) {
                commit('setCurrentProduto', payload)
            },
            set_Itens({commit}, payload ) {
                commit('setItens', payload)
            },
            set_Total_Sols({commit}, payload ) {
                commit('setTotalSols', payload)
            },
            set_Sols_Pendentes({commit}, payload ) {
                commit('setSolsPendentes', payload)
            },
            set_Sols_Em_Andamento({commit}, payload ) {
                commit('setSolsEmAndamento', payload)
            },
            set_Sols_Atendidas({commit}, payload ) {
                commit('setSolsAtendidas', payload)
            },
            set_Default_Language({commit}, payload ) {
                commit('setDefaultLanguage', payload)
            },
            set_Suported_Languages({commit}, payload ) {
                commit('setSuportedLanguages', payload)
            },
            set_Login_Info({commit}, payload ) {
                commit('setLoginInfo', payload)
            },
            set_Nota_Atual({commit}, payload ) {
                commit('setNotaAtual', payload)
            },
            set_Notificacoes({commit}, payload ) {
                commit('setNotificacoes', payload)
            },
            set_Ultimas_Transacoes({commit}, payload ) {
                commit('setUltimasTransacoes', payload)
            },
            set_Primeiros_Itens({commit}, payload ) {
                commit('setPrimeirosItens', payload)
            },
            set_Demais_Itens({commit}, payload ) {
                commit('setDemaisItens', payload)
            },
            set_Root_Item({commit}, payload ) {
                commit('setRootItem', payload)
            },
            set_Avaliacao({commit}, payload ) {
                commit('setAvaliacao', payload)
            },
            set_Perguntas({commit}, payload ) {
                commit('setPerguntas', payload)
            },
            set_IdParceiro({commit}, payload ) {
                commit('setIdParceiro', payload)
            }
        },
        getters: {
            GetComponentName(state) {
                return state.componentName
            },
            GetCurrentItem(state) {
                return state.currentItem
            },
            GetCurrentProduto(state) {
                return state.currentProduto
            },
            GetItens(state) {
                return state.itens
            },
            GetTotalSols(state) {
                return state.totalSols
            },
            GetSolsPendentes(state) {
                return state.solsPendentes
            },
            GetSolsEmAndamento(state) {
                return state.solsEmAndamento
            },
            GetSolsAtendidas(state) {
                return state.solsAtendidas
            },
            GetDefaultLanguage(state) {
                return state.defaultLanguage
            },
            GetSuportedLanguages(state) {
                return state.suportedLanguages
            },
            GetLoginInfo(state) {
                return state.loginInfo
            },
            GetNotaAtual(state) {
                return state.notaAtual
            },
            GetNotificacoes(state) {
                return state.notificacoes
            },
            GetUltimasTransacoes(state) {
                return state.ultimasTransacoes
            },
            GetPrimeirosItens(state) {
                return state.primeirosItens
            },
            GetDemaisItens(state) {
                return state.demaisItens
            },
            GetRootItem(state) {
                return state.rootItem
            },
            GetAvaliacao(state) {
                return state.avaliacao
            },
            GetPerguntas(state) {
                return state.perguntas
            },
            GetIdParceiro(state) {
                return state.idParceiro
            }
        }
    }
)
