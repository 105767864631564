<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
            <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="mb-4"><span class="material-icons icon">receipt_long</span> {{$t('avaliacoes')}}</h5>
                <h6>{{$t('vejaasavaliacoesquejafezdohotel')}}</h6>
            </div>
            <div class="main-container mt-5">
			
			<div class="main-container">
                <div class="container mb-1">
                    <div class="card border-0 mb-3" :v-if="lista.resps != null"  v-for="(respalval, idx) in lista" :key="idx">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-auto es-middle">
                                    <div class="avatar avatar-40 border-0 bg-success-light rounded-circle text-success">
                                        <i class="material-icons vm text-template">assessment</i>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <h5 class="mb-0">{{respalval.desctipoavaliacao}}</h5>
                                </div>
                                <!-- <div class="col-auto">
                                    <five-star :index="respalval.media/5"></five-star>
                                </div> -->
                            </div>
                            <div class="row text-center mt-2">
                                <div v-if="respalval.resps.length>0" class="col-auto">
                                    <h6 class="mb-0">{{$t('media')}}</h6>
                                    <h6 class="mb-0">{{respalval.media}}</h6>
                                </div>
                                <div class="col-auto">
                                    <div class="es-btn" @click="refazerAvaliacao(respalval.idtipoavaliacao)">
                                        {{$t(respalval.resps.length>0?'refazeravaliacao':'fazeravaliacao')}}</div>
                                </div>
                            </div>
                        </div> 
                        <div class="border border-info my-3 mx-3">
                            <div class="card-body my-0 py-0" v-for="(resp, indice) in respalval.resps" :key="indice" >
                                <div class="card-header">
                                    <div class="row">
                                        <h6 class="es-pergunta">
                                            <i class="material-icons vm text-template">{{resp.icone}}</i>
                                            {{resp.textopergunta}}
                                        </h6>
                                        <div class="es-box">
                                            <div v-for="(item,index) in notas" :key="index">
                                                <div :class="getClass(item,resp.nota)">
                                                </div>
                                                <div style="text-align:center;">{{item.texto}}</div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="respalval.resps.length>0" class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <p class="mb-1 text-secondary small">{{formatarData(respalval.dataavaliacao)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </main>
      <Footer :itemdestacar="3"/>
  </div>
</template>

<script>
import Loader from './Loader.vue'
import Menu from './Menu.vue'
import Header from './Header.vue'
import Footer from './Footer.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment"
//import FiveStar from './FiveStar.vue'
export default {
    components: {
         Loader, Menu, Header, Footer, //FiveStar
    },
    data() {
        return {
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
            lista: [],
            contestacao: '',
            notas: [
                {valor: 1, texto: this.$t('resposta.ruim'), classePoss: 'ruim', classeResp: 'ruim-selected'},
                {valor: 2, texto: this.$t('resposta.regular'), classePoss: 'regular', classeResp: 'regular-selected'},
                {valor: 3, texto: this.$t('resposta.bom'), classePoss: 'bom', classeResp: 'bom-selected'},
                {valor: 4, texto: this.$t('resposta.otimo'), classePoss: 'otimo', classeResp: 'otimo-selected'},
                {valor: 5, texto: this.$t('resposta.excelente'), classePoss: 'excelente', classeResp: 'excelente-selected'},
            ]
        }
    },
    methods: {
        getClass(item,nota) {
            var result = (item.valor==nota?item.classeResp:item.classePoss)
            return result;
        },        
        refazerAvaliacao(idtipoavaliacao) {
            this.$store.dispatch('set_Avaliacao', idtipoavaliacao);
            var path = `resposta/${idtipoavaliacao}`
            console.log(path)
            var newURL = window.location.protocol + "//" + window.location.host + "/" + path
            //console.log(newURL)
            //this.$router.push(newURL)
            window.location = newURL
        },
        formatarData(aDate) {
            //return moment(aDate).format('DD-MM-YY HH:MM');
            return moment(aDate).locale(this.$i18n.locale).format('LLL');
        },  
        buscarAvaliacoes() {
            var paramObj = {
                    idhotel : this.$store.getters.GetLoginInfo.idhotel,
                    idreservasfront: this.$store.getters.GetLoginInfo.idreservasfront,
                    idhospedepms : this.$store.getters.GetLoginInfo.idhospedepms,
                    idioma: this.$i18n.locale,
                    orderby: 'posicao'
                }; 
           //console.log(JSON.stringify(paramObj))
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/select/avaliacao', paramObj,  {headers: this.headers})
                .then(response => {
                if (response.status == 200) {
                    this.lista = response.data
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        }
    },
    mounted() {
        this.buscarAvaliacoes();
    }

}
</script>

<style>
.border-info {
    border-radius: 10px  !important;
}
.es-middle {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.es-pergunta{
    font-size: 1.2rem;
    padding-right: 1.2rem;
}
.es-escala {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.resp-possivel {
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: rgb(223, 219, 219);
}
.resp-escolhida {
    padding: 0.5rem;
    border-radius: 1rem;
    color:white;
    background-color: blueviolet;
}
.es-btn {
    color: white;
    background-color: rgb(23,149,228);
    cursor: pointer;
    border-radius: 0.8rem;
    padding: 0.6rem;
}
.es-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.ruim {
    background: url(../../img/ruim.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
.ruim-selected {
    background: url(../../img/ruim-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
@media only screen and (max-width: 400px) {
  [class*="ruim"] {
    width: 35px!important;
    min-width: 35px;
    min-height: 35px;
  }
}
.regular {
    background: url(../../img/regular.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
.regular-selected {
    background: url(../../img/regular-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
@media only screen and (max-width: 400px) {
  [class*="regular"] {
    width: 35px!important;
    min-width: 35px;
    min-height: 35px;
  }
}
.bom {
    background: url(../../img/bom.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
.bom-selected {
    background: url(../../img/bom-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
@media only screen and (max-width: 400px) {
  [class*="bom"] {
    width: 35px!important;
    min-width: 35px;
    min-height: 35px;
  }
}
.otimo {
    background: url(../../img/otimo.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
.otimo-selected {
    background: url(../../img/otimo-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
@media only screen and (max-width: 400px) {
  [class*="otimo"] {
    width: 35px!important;
    min-width: 35px;
    min-height: 35px;
  }
}
.excelente {
    background: url(../../img/excelente.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
.excelente-selected {
    background: url(../../img/excelente-selected.png) no-repeat center ;
    background-size: 100% 100%;
    background-position: cover;
    width: 20%;
    min-width: 40px;
    min-height: 40px;
    z-index: 1000;
}
@media only screen and (max-width: 400px) {
  [class*="excelente"] {
    width: 35px!important;
    min-width: 35px;
    min-height: 35px;
  }
}
</style>