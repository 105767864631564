<template>
    <div class="main-container">
        <div class="container-center" v-for="tipo in tiposSolicitacao" :key="tipo.idtiposolicitacao">
            <div class="card" style="width: 330px;">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h6 class="mb-1">{{tipo.titulo}}</h6>
                            <p class="mb-1">{{tipo.subtitulo}}</p>
                            <ul class="list-group list-group-flush">
                                <li v-for="item in tipo.itens" :key="'item_'+item.idtiposolicitacaoitem" class="list-group-item mb-2 mt-3">
                                    <template v-if="item.titulo.toLowerCase()!=tipo.titulo.toLowerCase() && !item.chaveativadora">
                                        <h6>{{item.titulo}}</h6>
                                    </template>
                                    <span>{{item.descricao}}</span>
                                    <!-- Quantidade -->
                                    <div v-if="item.tipo == '1'" class="input-group cart-count cart-count-lg rounded mt-1 mb-2 ml-3">
                                        <div class="input-group-prepend">
                                            <button @click="decrementar(item)" class="btn btn-outline-secondary rounded" type="button">-</button>
                                        </div>
                                        <input type="text" class="form-control rounded" placeholder="" :value="item.valordefault">
                                        <div class="input-group-append">
                                            <button @click="incrementar(item)" class="btn btn-outline-secondary rounded" type="button">+</button>
                                        </div>
                                    </div>
                                    <!-- Com chave ativadora -->
                                    <!-- <div v-if="item.chaveativadora">
                                        <label class="ponteiro px-2" :for="'itemSwitch_'+item.idtiposolicitacaoitem"><b>{{item.titulo}}</b></label>
                                        <input @change="verificarBotaoConfirmar(tipo,item)" class="ponteiro px-2" value="false" type="checkbox" :name="'item_'+item.idtiposolicitacaoitem" 
                                            :id="'itemSwitch_'+item.idtiposolicitacaoitem">
                                    </div> -->
                                    <div v-if="item.chaveativadora" 
                                        class="custom-control custom-switch"
                                    >
                                        <input  type="checkbox" class="custom-control-input" 
                                            :id="'itemSwitch_'+item.idtiposolicitacaoitem" 
                                            value="false"
                                            @change="verificarBotaoConfirmar(tipo,item)" 
                                        >
                                        <label class="custom-control-label" :for="'itemSwitch_'+item.idtiposolicitacaoitem"><b>{{item.titulo}}</b></label>
                                    </div>
                                    <!-- Horário certo -->
                                    <div v-if="item.tipo == 3">
                                        <label class="form-control-label">{{$t('informarhorario')}}: </label>
                                        <input type="time" class="form-control" autofocus="" v-model="item.valordefault">
                                    </div>
                                    <!-- Data para agendamento -->
                                    <div v-if="item.incluirnaagenda">
                                        <label class="form-control-label mt-2">{{$t('informardata')}}: </label>
                                        <input type="date" class="form-control" autofocus="" v-model="item.datahoraagendamento">
                                    </div>
                                    <!-- Info Adicional, aviso de alerta e horário de atendimento -->
                                    <div :id="'itemExtra_'+item.idtiposolicitacaoitem" :style="item.chaveativadora?'display:none;':''">
                                        <div v-if="item.horarioatendimento" class="py-2">
                                            <label class="form-control-label">{{$t('periodo')}}:</label>
                                            <div class="row mb-2 m-0">
                                                <div class="col-6">
                                                    &nbsp; {{$t('de')}}:<input v-model="horariode" type="time" 
                                                        class="form-control" autofocus=""
                                                        @change="checkTimes(item)">
                                                </div>
                                                <div class="col-6">
                                                    &nbsp; {{$t('ateas')}}<input v-model="horarioate" type="time" 
                                                        class="form-control" autofocus=""
                                                        @change="checkTimes(item)">
                                                </div>
                                            </div>
                                            <template v-if="!validTimes">
                                                <div class="horario-total">
                                                    <div class="fora-horario">
                                                        Fora do horário permitido.
                                                    </div>
                                                    <div class="horario-permitido" v-if="!validTimes">
                                                        Escolha entre esses horários: {{item.horariodisp}}
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-if="item.tipo == '6'" class="py-2">
                                            <label class="form-control-label">{{$t('selecioneositens')}}:</label>
                                            <produtos-grupos :grupos="item.gruposprodutos" :iditem="item.idtiposolicitacaoitem"
                                                @change="verificarBotaoConfirmar(tipo,item)"
                                            ></produtos-grupos>
                                        </div>                                        
                                        <div v-if="item.infoadicional">
                                            <label class="form-control-label">{{$t('informacaoadicional')}}:</label>
                                            <textarea 
                                                v-model="item.informacaoadicional" class="form-control" 
                                                :id="'infoAdicional_'+item.idtiposolicitacaoitem" rows="3"></textarea>
                                        </div>
                                        <div v-if="item.infoalerta" class="py-2">
                                            <div style="color:orange;">{{item.infoalerta}}</div>
                                        </div>
                                        <!-- Assinatura -->
                                        <div v-if="item.tipo == '6'" class="py-2">
                                            <label class="form-control-label">{{$t('suaassinatura')}}:</label>
                                            <assinatura :nome="$store.getters.GetLoginInfo.nome"
                                                @confirmar="(evt) => {guardarImagemAss(evt)}"
                                                @limpar="limparImagemAss()"
                                            ></assinatura>
                                        </div>
                                    </div>
                                    <!-- Botão individual para cada item ou não -->
                                    <div v-if="tipo.confirmacaoindividual" class="my-2">
                                        <button @click="solicitarItem(tipo,item)" 
                                            :id="'confirmarIndividualButton_'+item.idtiposolicitacaoitem"
                                            :disabled="verificarItem(item)||!validTimes"
                                            class="btn btn-block btn-default rounded mr-3"
                                        >{{$t('confirmarsolicitacao')}}</button>    
                                    </div>
                                </li>
                                <!-- Botão único para cada todos os itens -->
                                <li v-if="!tipo.confirmacaoindividual" class="list-group-item">
                                    <button @click="solicitarTipo(tipo)" 
                                        :id="'confirmarButton_'+tipo.idtiposolicitacao"
                                        :disabled="verificarQuantidades(tipo)"
                                        class="btn btn-block btn-default rounded mr-3"
                                    >{{$t('confirmarsolicitacao')}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import ProdutosGrupos from './ProdutosGrupos.vue'
import Assinatura from './Assinatura.vue'

export default {
    props: {
        setor: {
            type: Number,
            required: true
        },
        tipos: {
            type: String,
            required: false,
            default: ''
        },
        tiposNot: {
            type: String,
            required: false,
            default: ''
        }
    },
    components: { ProdutosGrupos, Assinatura },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tiposSolicitacao: [],
            horariode: '12:00',
            horarioate: '14:00',
            validTimes: true,
            assinaturaSrc: null
        }
    },
    computed: {
        // pedidoVazio(item) {
        //     var result = true
        //     for (var j = 0; j < item.gruposprodutos.length; j++) {
        //         for (var k = 0; k < item.gruposprodutos[j].produtos.length; k++) {
        //             var umProduto = item.gruposprodutos[j].produtos[k]
        //             if (parseInt(umProduto.qtde) > 0) {
        //                 result = false;
        //                 break;
        //             }
        //         }
        //         if (!result) break;
        //     }
        //     return result;
        // },		
    },
    methods: {
        pedidoVazio(item) {
            var result = true
            if (item.gruposprodutos) {
                for (var j = 0; j < item.gruposprodutos.length; j++) {
                    for (var k = 0; k < item.gruposprodutos[j].produtos.length; k++) {
                        var umProduto = item.gruposprodutos[j].produtos[k]
                        if (parseInt(umProduto.qtde) > 0) {
                            result = false;
                            break;
                        }
                    }
                    if (!result) break;
                }
            }
            return result;
        },
        limparImagemAss() {
            this.assinaturaSrc = null
        },
        guardarImagemAss(e) {
            this.assinaturaSrc = e
        },
        salvarAssinatura(id) {
            var params = {
                "tipo" : "image/png",
                "nome" : "Assinatura",
                "dados" : this.assinaturaSrc,
                "tabela": "hotlinesolicitacoes",
                "fk": "assinatura",
                "chave": "idhotlinesolicitacoes",
                "valor": id
				}
            axios.post(CONSTANTS.API_URL + '/fdguest/imagem/upinsert',
            params, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    //console.log('Imagem salva.')
                    this.$router.push("/sucesso")                    
                }
            })
        },
        valorDe(timeStr) {
            if (timeStr && timeStr.trim()) {
                return parseInt(timeStr.replace(/[:]/g, ''))
            } else {
                return 0
            }
        },
        horariosPermitidos(item) {
            var resultArray = []
            if (item.horariodisp && item.horariodisp.trim()) {
                var splitedTimes = item.horariodisp.split(';')
                for (var i = 0; i < splitedTimes.length; i++) {
                    var timePeriod = splitedTimes[i]
                    var times = timePeriod.split('-')
                    if (times.length == 2) {
                        var a = this.valorDe(times[0])
                        var b = this.valorDe(times[1])
                        resultArray.push([a,b])
                    }
                }
            }
            //console.log(JSON.stringify(resultArray))
            return resultArray
        },
        checkTimes(item) {
            var result = false
            var timesToCheck = this.horariosPermitidos(item)
            for (var i = 0; i < timesToCheck.length; i++){
                var limiteInferior = timesToCheck[i][0]
                var limiteSuperior = timesToCheck[i][1]
                var resultInRange = 
                    this.valorDe(this.horariode) >= limiteInferior
                    && this.valorDe(this.horariode) <= limiteSuperior
                    && this.valorDe(this.horarioate) >= limiteInferior
                    && this.valorDe(this.horarioate) <= limiteSuperior
                    && this.valorDe(this.horariode) <= this.valorDe(this.horarioate)
                if (resultInRange) {
                    result = true;
                    break;
                }
            }
            this.validTimes = result
            return result
        },
        verificarItem(item) {
            var result = true
            var el1 = document.getElementById('itemSwitch_'+item.idtiposolicitacaoitem)
            if (item.tipo == 1) {
                if (parseInt(item.valordefault) > 0) {
                    result = false;
                }
            } else {
                if (item.tipo == 2 && !item.chaveativadora) {
                    result = false
                } else {
                    if (item.tipo == 3) {
                        result = false
                    } else {
                        if (item.tipo == 6) {
                            result = this.pedidoVazio(item) || !this.assinaturaSrc
                        } else {
                            if (el1) {
                                // result = result || !el1.checked;
                                result = !el1.checked;
                            } else {
                                result = true;
                            }
                        }
                    }
                }
            }
            //console.log('verificarItem:'+result)
            return result
        },
        verificarBotaoConfirmar(tipo,item) {
            var el1 = document.getElementById('confirmarIndividualButton_'+item.idtiposolicitacaoitem)
            var el2 = document.getElementById('infoAdicional_'+item.idtiposolicitacaoitem)
            var el3 = document.getElementById('itemSwitch_'+item.idtiposolicitacaoitem)
            var el4 = document.getElementById('itemExtra_'+item.idtiposolicitacaoitem)
            if (el3.checked) {
                el4.style.display = 'block'
                item.selected = true
            } else {
                el4.style.display = 'none'
                item.selected = false
            }
            if (tipo.confirmacaoindividual) {
                if (el1&&el2&&el3) {
                    el1.disabled = this.verificarItem(item);
                    if (!el1.disabled) {
                        if (el3.checked && item.tipo != 6) {
                            el2.focus();
                        }
                    }
                }
            } else {
                var el = document.getElementById('confirmarButton_'+tipo.idtiposolicitacao)
                if (el) {
                    el.disabled = this.verificarQuantidades(tipo)
                    if (!el.disabled) {
                        if (el2) {
                            if (el3.checked) {
                                el2.focus();
                            }
                        }
                    }
                }
            }
        },
        verificarQuantidades(tipo) {
            var result = true
            for (var i = 0; i < tipo.itens.length; i++) {
                var item = tipo.itens[i]
                if (item.tipo == 1) {
                    if (parseInt(item.valordefault) > 0) {
                        result = false;
                        break;
                    }
                }
                if (item.tipo == 2) {
                    if (item.chaveativadora) {
                        var el = document.getElementById('itemSwitch_'+item.idtiposolicitacaoitem)
                        if (el) {
                            if (el.checked) {
                                result = false;
                                break;
                            }
                        }
                    } else {
                        item.selected = true
                        result = false;
                        break;
                    }
                }
                if (item.tipo == 3) {
                    result = false;
                    break;
                }
                if (item.tipo == 6) {
                    result = this.pedidoVazio(item) || !this.assinaturaSrc
                    break;
                }
            }
            //console.log('result:'+result)
            return result;
        },
        incrementar(item) {
            item.valordefault++
            if (item.valordefault > 0) {
                var el4 = document.getElementById('itemExtra_'+item.idtiposolicitacaoitem)
                el4.style.display = 'block'
                var el1 = document.getElementById('confirmarIndividualButton_'+item.idtiposolicitacaoitem)
                if (el1) {
                    el1.disabled = this.verificarItem(item)
                }
            }
        },
        decrementar(item) {
            if (item.valordefault>0) {
                item.valordefault -- 
                if (item.valordefault == 0) {
                    var el4 = document.getElementById('itemExtra_'+item.idtiposolicitacaoitem)
                    el4.style.display = 'none'
                }
            }
        },
        itemSelecionado(item) {
            var result = true
            switch (item.tipo) {
                case 1: // Tipo Quantidade
                    result = parseInt(item.valordefault) > 0
                    break;
                case 2: // Tipo Simples
                    if (item.chaveativadora) {
                        var el = document.getElementById('itemSwitch_'+item.idtiposolicitacaoitem)
                        if (el) result = el.checked
                    }
                    break;
                case 3: // Tipo Horário
                    result = this.valorDe(item.valordefault) > 0
                    break;
                case 4: // Tipo Período de Tempo (não implementado)
                    break;
                case 5: // Tipo Período de Tempo Dual (não implementado)
                    break;
                case 6: // Tipo Produtos
                    result = !this.pedidoVazio(item) && this.assinaturaSrc
                    break;
                default:
                    break;
            }
            return result
        },
        solicitarTipo(tipo) {
            //console.error(`solicitarTipo`)
            var str = this.$store.getters.GetLoginInfo.nome;
            var res = str.split(" ");
            var nome = res[0];
            var sobrenome = (res.length==1)?'':res[res.length-1];
            var descricaoSol = ''
            var descricaoItem = ''
            var itensSelecionados = []
            var data = ''
            var datahoraagendamento = null
            var idtipoitem = null
            var tipoitem = null
            
            if (tipo.itens.length > 0) {
                tipoitem = tipo.itens[0]['tipo']
                idtipoitem = tipo.itens[0]['idtiposolicitacaoitem']
            }
            
            for (var i = 0; i < tipo.itens.length; i++) {
                var umItem = tipo.itens[i]
                umItem.selected = this.itemSelecionado(umItem)
                //console.error('umItem:'+JSON.stringify(umItem));
                if (umItem.selected) {
                    data = ''
                    if (umItem.incluirnaagenda) {
                        data = this.$d(umItem.datahoraagendamento).format('DD/MM/YY')
                        data = data ? ' - ' + data : ''
                        datahoraagendamento = `${umItem.datahoraagendamento} ${umItem.valordefault}`
                    }
                    if (!data && umItem.horarioatendimento) {
                        data = `; ${this.horariode} - ${this.horarioate}`
                    }
                    // Para tipo quantidade ou horário o valor default é utilizado
                    if (umItem.tipo == 1 || umItem.tipo == 3) {
                        descricaoItem = `${umItem.titulo}: ${umItem.valordefault}${data}; `
                    } else {
                        descricaoItem = `${umItem.titulo}${data}; `
                    }
                    if (umItem.infoadicional && umItem.informacaoadicional.trim() != '') {
                        descricaoItem += `\n${this.$t('informacaoadicional')}: ${umItem.informacaoadicional.trim()} `
                    }
                    descricaoItem += '\n'
                    umItem.texto = descricaoItem
                    descricaoSol += descricaoItem
                    itensSelecionados.push(umItem)
                }
            }
            //console.error(descricaoSol);
            var solicitacao = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : tipo.idhotlinesetores,
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
                "idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" :  descricaoSol,
				"idtiposolicitacao" : tipo.idtiposolicitacao,
                "idtiposolicitacaoitem" : idtipoitem,
                "datahoraagendamento": datahoraagendamento,
                "multipla": tipo.solicitacoesmultiplas,
                "itens": itensSelecionados,
                "gruposprodutos": tipoitem == 6 ? tipo.itens[0]['gruposprodutos'] : null
            };
            this.solicitar(solicitacao, (tipoitem == 6))
        },
        solicitarItem(tipo,item) {
            //console.error(`JSON.stringify(item)`)
            var str = this.$store.getters.GetLoginInfo.nome;
            var res = str.split(" ");
            var nome = res[0];
            var sobrenome = (res.length==1)?'':res[res.length-1];
            var descricaoSol = ''
            var data = ''
            var datahoraagendamento = null
            var idtipoitem = null
            var tipoitem = null
            //console.error(JSON.stringify(item))
            if (item) {
                idtipoitem = item.idtiposolicitacaoitem
                tipoitem = item.tipo
                if (item.incluirnaagenda) {
                    data = this.$d(item.datahoraagendamento).format('DD/MM/YY')
                    data = data ? ' - ' + data : ''
                    datahoraagendamento = `${item.datahoraagendamento} ${item.valordefault}`
                }
                if (!data && item.horarioatendimento) {
                    data = `; ${this.horariode} - ${this.horarioate}`
                }
                if (item.tipo == 1 || item.tipo == 3) {
                    descricaoSol += `${item.titulo}: ${item.valordefault}${data}; `
                } else {
                    descricaoSol += `${tipo.titulo}: ${item.titulo}${data}; `
                }
                if (item && item.infoadicional && item.informacaoadicional.trim() != '') {
                    descricaoSol += `${item.informacaoadicional.trim()} `
                }
            }
            var solicitacao = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : tipo.idhotlinesetores,
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
                "idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" :  descricaoSol,
				"idtiposolicitacao" : tipo.idtiposolicitacao,
                "idtiposolicitacaoitem" : idtipoitem,
                "datahoraagendamento": datahoraagendamento,
                "multipla": tipo.solicitacoesmultiplas,
                "itens": null,
                "gruposprodutos": tipoitem == 6 ? item.gruposprodutos : null,
                "localizacao" : item.titulo.length > 0 ? item.titulo : null
            };
            //console.error(JSON.stringify(solicitacao))
            this.solicitar(solicitacao, (tipoitem == 6))
        },
        solicitar(solicitacao, saveSig) {
            console.error(JSON.stringify(solicitacao))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
                solicitacao, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    //console.log(JSON.stringify(response.data))
                    if (saveSig) {
                        this.salvarAssinatura(response.data.ids[0])
                    } else {
                        this.$router.push("/sucesso")
                    }
                }
            })
        },
        carregarTiposSolicitacao() {
            var params = {
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "idhotlinesetores" : this.setor,
                "itensnulos": false,
                "ativo": true,
                "interna": false
            };
            if (this.tipos) {
                params.tipos = this.tipos
            }
            if (this.tiposNot) {
                params.tiposNot = this.tiposNot
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select',
                params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.tiposSolicitacao = response.data
                }
            })
        }        
    },
    created() {
        this.carregarTiposSolicitacao();
    }
}
</script>
<style scoped>
    .container-center {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        width: 100%;
    }

</style>