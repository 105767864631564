<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
            <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="mb-4"><span class="material-icons icon">receipt_long</span> {{$t('solicitacoes')}}</h5>
                <h6>{{$t('acompanhetodosositenssolicitados')}}</h6>	
            </div>
            <div class="main-container mt-5">
			
			<div class="main-container">
                <div class="container mb-1">
                    <div class="accordion accordion-primary"
                        id="accordion-sols" 
                    >
                        <div class="accordion__item"
                            v-for="(solsetor,index) in lista" :key="index"
                            :id="'accordion-item-'+index" 
                        >
                            <div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#default-collapse-'+index">
                                <div class="row">
                                <div class="col-3 align-self-center pr-0">
                                    <div class="avatar avatar-40 border-0 bg-success-light rounded-circle text-success mx-1">
                                        <i class="material-icons vm text-template">{{solsetor.icone}}</i>
                                    </div>
                                </div>
                                <div class="col-7 align-self-center pr-0">
                                    <h6 class="">{{solsetor.setor}}</h6>
                                    <span class="accordion__header--text">{{solsetor.sols.length==0?$t('nenhumasolicitacao')+'.':solsetor.sols.length + ' ' + $t('solicitacoes') + '.'}} </span>
                                </div>
                                <div class="col-2 text--right">
                                    <span class="accordion__header--indicator"></span>
                                </div>
                                </div>
                            </div>
                            <div :id="'default-collapse-'+index" class="collapse" data-parent="#accordion-sols">
                                <div class="accordion__body--text px-0">
                                    <div class="border border-info my-3 mx-3" v-for="sol in solsetor.sols" :key="sol.idhotlinesolicitacoes" >
                                        <div class="card-body " >
                                            <div class="row">
                                                <div class="col-7">
                                                    <button :class="{ 'btn-warning' : sol.idstatus == 5 , 'btn-success' : sol.idstatus == 3, 'btn-secondary' : sol.idstatus == 1,'btn-primary' : sol.idstatus == 2,'btn-dark' : sol.idstatus == 6,'btn-danger' : sol.idstatus == 4  }" class=" mb-3 btn btn-sm  rounded">{{sol.status}}</button>
                                                </div>
                                                <div class="col-4 mr-1" style="text-align:right;"
                                                    v-if="sol.idstatus!=3&&sol.idstatus!=6&&sol.idstatus!=4&&sol.tipoitem!=6">
                                                    <button @click="expandirCancelamento(sol.idhotlinesolicitacoes)" class="btn-danger mb-3 btn btn-sm rounded">{{$t('cancelar')}}</button>
                                                </div>
                                            </div>
                                            <div :id="'div_cancel_'+sol.idhotlinesolicitacoes" class="form-group float-label d-none">
                                                <textarea v-model="cancelamento" class="form-control" :id="'text_cancel_'+sol.idhotlinesolicitacoes" rows="2"></textarea>
                                                <label class="form-control-label">{{$t('descrevacancelamento')}}</label>
                                                <button @click="cancelarSolicitacao(sol.idhotlinesolicitacoes)" 
                                                    :disabled="cancelamento.length==0"
                                                    class="btn btn-block btn-default rounded mr-3 mt-3">{{$t('confirmarcancelamento')}}</button>
                                            </div>
                                            <h6 class="text-center  mb-1 bold">{{sol.deschotlinesetor}}</h6>
                                            <p class="timeline-text">{{sol.textohotlinesolicitacoes}} </p>  
                                            <div class="ponteiro text-center text-primary" @click="toggle(sol)">
                                                <span v-if="!exibirHistorico" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                                                <span v-if="exibirHistorico" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                                                {{toggleText(sol)}}
                                            </div>
                                            <div :id="'historico-solicitacao-'+sol.idhotlinesolicitacoes" style="display:none;width:100%;">
                                                <solicitacao-historico 
                                                    :idhotlinesolicitacoes="sol.idhotlinesolicitacoes">
                                                </solicitacao-historico>
                                            </div>                                
                                            <a v-if="sol.idstatus == 3 && sol.datacontestacao == null" @click="campContestar(sol.idhotlinesolicitacoes)" class="btn btn-sm btn-default my-2"><img src="../../img/refresh.svg" width="10"> {{$t('contestar')}}</a>
                                            <div :id="sol.idhotlinesolicitacoes" class="form-group float-label d-none">
                                                <textarea v-model="contestacao" class="form-control" :id="'text_'+sol.idhotlinesolicitacoes" rows="2"></textarea>
                                                <label class="form-control-label">{{$t('descrevacontestacao')}}</label>   
                                                <button @click="contestarSolicitacao(sol.idhotlinesolicitacoes)" class="btn btn-block btn-default rounded mr-3 mt-3">{{$t('confirmarcontestacao')}}</button>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <p class="text-default small">Cod Res: {{$store.getters.GetLoginInfo.idreservasfront}}</p>
                                                </div>
                                                <div class="col-auto align-self-center text-right">
                                                    <p class="mb-1 text-secondary small">{{formatarData(sol.datasolicitacao)}}<strong> #{{sol.idhotlinesolicitacoes}}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card border-0 mb-3" :v-if="lista.sols != null"  v-for="(solssetor, idx) in lista" :key="idx">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-auto pr-0">
                                    <div class="avatar avatar-40 border-0 bg-success-light rounded-circle text-success">
                                        <i class="material-icons vm text-template">{{solssetor.icone}}</i>
                                    </div>
                                </div>
                                <div class="col align-self-center pr-0">
                                    <h6 class="mb-0">{{solssetor.setor}}</h6>
                                    <p class="text-secondary">{{$t('solicitacoes')}}</p>
                                </div>
                            </div>
                        </div> 
                        <div class="border border-info my-3 mx-3" v-for="sol in solssetor.sols" :key="sol.idhotlinesolicitacoes" >
                            <div class="card-body " >
                                <div class="row">
                                    <div class="col-7">
                                        <button :class="{ 'btn-warning' : sol.idstatus == 5 , 'btn-success' : sol.idstatus == 3, 'btn-secondary' : sol.idstatus == 1,'btn-primary' : sol.idstatus == 2,'btn-dark' : sol.idstatus == 6,'btn-danger' : sol.idstatus == 4  }" class=" mb-3 btn btn-sm  rounded">{{sol.status}}</button>
                                    </div>
                                    <div class="col-4 mr-1" style="text-align:right;"
                                        v-if="sol.idstatus!=3&&sol.idstatus!=6&&sol.idstatus!=4&&sol.tipoitem!=6">
                                        <button @click="expandirCancelamento(sol.idhotlinesolicitacoes)" class="btn-danger mb-3 btn btn-sm rounded">{{$t('cancelar')}}</button>
                                    </div>
                                </div>
                                <div :id="'div_cancel_'+sol.idhotlinesolicitacoes" class="form-group float-label d-none">
                                    <textarea v-model="cancelamento" class="form-control" :id="'text_cancel_'+sol.idhotlinesolicitacoes" rows="2"></textarea>
                                    <label class="form-control-label">{{$t('descrevacancelamento')}}</label>
                                    <button @click="cancelarSolicitacao(sol.idhotlinesolicitacoes)" 
                                        :disabled="cancelamento.length==0"
                                        class="btn btn-block btn-default rounded mr-3 mt-3">{{$t('confirmarcancelamento')}}</button>
                                </div>
                                <h6 class="text-center  mb-1 bold">{{sol.deschotlinesetor}}</h6>
                                <p class="timeline-text">{{sol.textohotlinesolicitacoes}} </p>  
                                <div class="ponteiro text-center text-primary" @click="toggle(sol)">
                                    <span v-if="!exibirHistorico" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                                    <span v-if="exibirHistorico" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                                    {{toggleText(sol)}}
                                </div>
                                <div :id="'historico-solicitacao-'+sol.idhotlinesolicitacoes" style="display:none;width:100%;">
                                    <solicitacao-historico 
                                        :idhotlinesolicitacoes="sol.idhotlinesolicitacoes">
                                    </solicitacao-historico>
                                </div>                                
                                <a v-if="sol.idstatus == 3 && sol.datacontestacao == null" @click="campContestar(sol.idhotlinesolicitacoes)" class="btn btn-sm btn-default my-2"><img src="../../img/refresh.svg" width="10"> {{$t('contestar')}}</a>
                                <div :id="sol.idhotlinesolicitacoes" class="form-group float-label d-none">
                                    <textarea v-model="contestacao" class="form-control" :id="'text_'+sol.idhotlinesolicitacoes" rows="2"></textarea>
                                    <label class="form-control-label">{{$t('descrevacontestacao')}}</label>   
                                    <button @click="contestarSolicitacao(sol.idhotlinesolicitacoes)" class="btn btn-block btn-default rounded mr-3 mt-3">{{$t('confirmarcontestacao')}}</button>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <p class="text-default small">Cod Res: {{$store.getters.GetLoginInfo.idreservasfront}}</p>
                                    </div>
                                    <div class="col-auto align-self-center text-right">
                                        <p class="mb-1 text-secondary small">{{formatarData(sol.datasolicitacao)}}<strong> #{{sol.idhotlinesolicitacoes}}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
      </main>
      <Footer :itemdestacar="3"/>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment"
import SolicitacaoHistorico from './SolicitacaoHistorico.vue'

export default {
    components: {
         Loader, Menu, Header, Footer, SolicitacaoHistorico
    },
    data() {
        return {
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
            lista: [],
            contestacao: '',
            cancelamento: '',
            exibirHistorico: false,
            count: 0
        }
    },
    computed: {
    },
    methods: {
        toggleText(val) {
            var result = 'Exibir histórico'
            if (val) {
                result = val.exibirhistorico?this.$t('ocultarhistorico'):this.$t('exibirhistorico')
            }
            return result
        },
        toggle(sol) {
            var el = document.getElementById('historico-solicitacao-'+sol.idhotlinesolicitacoes);
            if (el) {
                if (el.style.display == 'none' || el.style.display == '') {
                    el.style.display = 'flex'
                    sol.exibirhistorico = true
                } else {
                    el.style.display = 'none'
                    sol.exibirhistorico = false
                }
            }
        },        
        cancelarSolicitacao(idhotlinesolicitacoes) {
            var params = {
                "idhotlinesolicitacoes": idhotlinesolicitacoes,
                "datacancelamento": moment(),
                "textocancelamento": this.cancelamento,
                "idstatus": 6,
                "idusuario" : "1"
            }
            console.log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update',
             params , {headers: this.headers}).then(response => {   
                if(response.status == 200) {
                    this.$router.push("/sucesso")
                }
            })
        },
        contestarSolicitacao(idhotlinesolicitacoes) {
            var contest = {
                "textocontestacao": this.contestacao,
                "idhotlinesolicitacoes": idhotlinesolicitacoes,
                "dataatendimento": null,
                "idstatus": 5,
                "idusuario" : "1"
            }
            //console.error(JSON.stringify(contest));
            axios.put(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/update',
             contest , {headers: this.headers}).then(response => {   
                if(response.status == 200) {
                    this.$router.push("/sucesso")
                }
            }).catch((e)=>{
                console.error(JSON.stringify(e));
            })
        },
        expandirCancelamento(idhotlinesolicitacoes) {
            var divcontest = document.getElementById('div_cancel_'+idhotlinesolicitacoes)
            if (divcontest) {
                if ( divcontest.classList.contains('d-none')) {
                    divcontest.classList.remove('d-none')
                    var textArea = document.getElementById('text_cancel_'+idhotlinesolicitacoes)
                    if (textArea) {
                        textArea.focus()
                    }
                } else {
                    divcontest.classList.add('d-none')
                }
            }
        },
        campContestar(idhotlinesolicitacoes) {
            var divcontest = document.getElementById(idhotlinesolicitacoes)
            if (divcontest) {
                if ( divcontest.classList.contains('d-none')) {
                    divcontest.classList.remove('d-none')
                    var textArea = document.getElementById('text_'+idhotlinesolicitacoes)
                    if (textArea) {
                        textArea.focus()
                    }
                } else {
                    divcontest.classList.add('d-none')
                }
            }
        },
        formatarData(aDate) {
            //return moment(aDate).format('DD-MM-YY HH:MM');
            return moment(aDate).locale(this.$i18n.locale).format('LLL');
        },  
        getSolicitacoes() {
            this.count += 1
            var paramObj = {
                    idhotel : this.$store.getters.GetLoginInfo.idhotel,
                    numreserva: this.$store.getters.GetLoginInfo.idreservasfront,
                    idhospedepms: this.$store.getters.GetLoginInfo.idhospedepms,
                    idioma: this.$i18n.locale,
                    interna: false
                }; 
            console.log(JSON.stringify(paramObj)+':'+this.count)
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select/setor', paramObj,  {headers: this.headers})
                .then(response => {
                if (response.status == 200) {
                    console.log(`response.data.length: ${response.data.length}`)
                    this.lista = response.data
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        }
    },
    mounted () {
        this.getSolicitacoes();
    }

}
</script>

<style>
.border-info {
    border-radius: 10px  !important;
}
</style>