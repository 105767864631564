<template>
	<div v-if="produtos && produtos.length>0">
		<!-- Grupos de Produtos -->
		<div v-for="(produto,index) in produtos" :key="index"
			:class="{ 'es-even': index%2==0, 'es-odd': index%2!=0}" class="row">
			<div class="col-6 px-0">
				<span>{{produto.descproduto}}</span>
			</div>
			<div class="col-2 px-0 text-right">
				<span>{{produto.precoimportado | formatarMoeda}}</span>
			</div>
			<div class="col-3 px-0">
				<div class="input-group cart-count cart-count-md rounded mt-0 mb-2 ml-3">
				<div class="input-group-prepend">
					<button @click="decrementar(produto)" class="btn btn-outline-secondary rounded small" type="button">-</button>
				</div>
				<input type="text" class="form-control rounded" placeholder="" :value="produto.qtde">
				<div class="input-group-append">
					<button @click="incrementar(produto)" class="btn btn-outline-secondary rounded small" type="button">+</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'produtos'
	],
    components: {
         
    },
    data() {
        return {

        }
	},
	methods: {
		incrementar(prod) {
			//console.log(JSON.stringify(prod))
			prod.qtde++
			this.$emit('change')
		},
		decrementar(prod) {
			//console.log(JSON.stringify(prod))
			if (prod.qtde > 0) {
				prod.qtde--
				this.$emit('change')
			}
		}
	},
	mounted() {

	}
}
</script>

<style>
.es-even {
	align-items: baseline;
	background-color: rgb(238, 239, 252)!important;
}
.es-odd {
	align-items: baseline;
	background-color: rgb(255, 255, 255)!important;
}
</style>