<template>
    <div id="loader" class="container-fluid h-100 loader-display">
        <div class="row h-100">
            <div class="align-self-center col">
                <div class="logo-loading">
                    <div class="icon icon-100 mb-4 rounded-circle">
                        <img src="img/guestfd.png" alt="" class="w-100">
                    </div>
                    <h4 class="text-default">GuestFD</h4>
                    <p class="text-secondary">App</p>
                    <div class="loader-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    mounted(){
        var s = document.getElementById('loader').style;
        s.opacity = 1;
        (function fade(){(s.opacity-=.05)<0?s.display="none":setTimeout(fade,70)})();
    }
}
</script>

<style>

</style>